@import url("https://fonts.googleapis.com/css?family=Jost");
@import url("https://fonts.googleapis.com/css?family=Lato");

@import url("https://fonts.googleapis.com/css?family=Open Sans");

@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css?family=Raleway");

/* General Styles */
.general-page {
  margin: auto;
  background-color: #f3f6fd;
}

.below-header-container {
  width: 80%;
  margin: 0 auto;
  background-color: #f3f6fd;
}


/* TOC Face Section */
#TOC-face {
  position: relative;
  margin-top: 3%;
  margin-bottom: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  height: 500px;
}

#TOC-face-pic {
  width: 100%;
  height: auto;
  position: absolute;
  top: -250px;
  left: 0;
  object-fit: cover;
  filter: brightness(0.7);
  transition: filter 0.3s ease;
}

#TOC-face-pic:hover {
  filter: brightness(1);
}

#TOC-face-text-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

#TOC-face-text {
  display: flex;
  justify-content: center;
  align-items: center;
  
  color: rgb(255, 255, 255);
  text-align: center;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  animation: fadeIn .75s ease;

  font-size: 64px;
  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

#TOC-text-new-face {
  display: flex;
  justify-content: center;
  align-items: center;
  
  color: rgb(255, 255, 255);
  text-align: center;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  animation: fadeIn .75s ease;

  font-size: 64px;
  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

#TOC-face-text-yes {
  display: flex;
  justify-content: center;
  align-items: center;
  
  color: rgb(255, 255, 255);
  text-align: center;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  animation: fadeIn .75s ease;

  font-size: 64px;
  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

/* Updated TOC Introduction Container */
#TOC-introduction-container {
  width: 70%;
  margin: 60px auto;
  padding: 50px 40px;
  text-align: center;
  background: #3d5a73; /* Solid color that mimics a gradient feel */
  border-radius: 15px;
  border: 4px solid #fff;
  position: relative;
  overflow: hidden;
}

#TOC-introduction-text-container {
  font-family: "Lato", sans-serif;
}

#TOC-introduction-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

#TOC-intro-title {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 2.5em;
  font-weight: 800;
  position: relative;
}

#TOC-introduction-text-main {
  color: #e0e0e0;
  font-family: "Jost", sans-serif;
  line-height: 1.8;
  margin: 0;
  font-size: 1.2em;
}

.general-toc-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  padding-bottom: 5%;
}

/* Traffic Box Container */
.traffic-box-container {
  width: 450px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 15px;
}

.traffic-box-image-container {
  width: 100%;
  height: 160px;
  overflow: hidden;
}

.traffic-box-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.traffic-box {
  padding: 20px;
}

.traffic-box h2 {
  font-size: 20px;
  color: #333;
  margin-bottom: 12px;
  font-weight: 600;
  line-height: 1.4;
}

.traffic-box h3 {
  font-size: 14px;
  color: #333;
  margin-bottom: -10px;
  font-weight: 600;
  line-height: 1.4;
  text-align: center;
}

.traffic-box p {
  font-size: 15px;
  margin-bottom: 20px;
  line-height: 1.6;
}

.service-item {
  padding: 10px 0;
  border-top: 1px solid rgb(185, 183, 183);
  width: 100%;
  box-sizing: border-box;
}

.service-item button {
  background-color: #4f46e5 ;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  height: 45px;
  margin-top: 1%;
}

.service-item button:hover {
  background-color: #3630a0;
}

.service-item-button {
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  width: calc(100% - 24px);
  height: 45px;
  margin-top: 1%;
  box-sizing: border-box;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;

}

.service-item-button:hover {
  background-color: #0056b3;
}

/* Traffic Title and Description */
.traffic-title {
  font-size: 20px;
  color: #f3f6fd;
  margin-bottom: 12px;
  font-weight: 600;
  line-height: 1.4;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;

}

.traffic-description {
  font-size: 15px;
  color: #868686;
  margin-bottom: 20px;
  line-height: 1.6;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;

}

.traffic-box-part-number {
  font-family: 'Poppins', sans-serif;
  
  font-style: normal;

  color: #f3f6fd;
}



/* Additional CSS from the Original File */

.hidden-box-text {
  opacity: 0;
  font-size: 15px;
  color: #666;
  margin-bottom: 20px;
  line-height: 1.6;
}

.general-heading {
  color: rgb(65, 72, 177);
  margin-top: 20px;
  border-bottom: 2px solid rgb(153, 158, 228);
  padding-bottom: 5px;
}

.general-text {
  margin: 10px 0;
}

.general-toc-item {
  background: rgb(227, 229, 233);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 15px;
  flex: 0 1 calc(50% - 40px);
  max-width: 400px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.general-toc-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.general-toc-item-title {
  margin-top: 0;
  font-size: 1.5em;
  color: rgb(65, 72, 177);
  position: relative;
  padding-bottom: 5px;
  transition: color 0.3s ease;
}

.general-toc-item-title::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: rgb(153, 158, 228);
  position: absolute;
  bottom: 0;
  left: 0;
}

.general-list {
  list-style-type: none;
  padding-left: 0;
}

.general-list-item {
  margin-bottom: 10px;
}

#toc-conclusion-container {
  width: 65%;
  margin: 50px auto;
  background-color: #ffffff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  text-align: center;
  font-size: 18px;
  transition: transform 0.3s, box-shadow 0.3s;
  animation: fadeIn 1s ease-out;
}

#toc-conclusion-container:hover {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  transform: translateY(-5px);
}

#toc-conclusion-text-container {
  font-family: "Lato", sans-serif;
}

#toc-conclusion-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

#toc-conclusion-title {
  color: #2d2d2d;
  margin-bottom: 8px;
  font-size: 2em;
  font-weight: 700;
}

#toc-page-conclusion-underline {
  height: 4px;
  width: 50px;
  background: #2d2d2d;
  border-radius: 2px;
  transition: width 0.3s ease;
}

#toc-conclusion-title:hover + #toc-page-conclusion-underline {
  width: 100px;
}

#toc-conclusion-text-main {
  color: #696969;
  font-family: "Jost", sans-serif;
  line-height: 1.6;
}









/* Existing styles for other sections */
#table-content-container-subs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  margin-bottom: 5%;
  margin-top: 2%;

  
}

#smaller-toc-cont-subs {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#new-TOC-name-subs {
  font-size: 60px;
  margin-bottom: 10px;

  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-style: normal;

  color: #273562 ;

}

#new-toc-description-subs {
  width: 50%;
  font-size: 20px;

  font-family: 'Raleway', sans-serif;
  
  font-style: normal;

  color: #273562 ;
}

#new-TOC-page-title-underline {
  height: 6px;
  width: 120px;
  margin-top: 12px;
  margin-bottom: 20px;
  border-radius: 50px;
  background: black;
  transition: width 0.3s ease;
}








#prep-TOC-face {
  position: relative;
  margin-top: 3%;
  margin-bottom: -20px;
  overflow: hidden;
  height: 500px;
}

#prep-TOC-face-pic {
  width: 100%;
  height: auto;
  position: absolute;
  top: -250px;
  left: 0;
  animation: fadeIn 0.65s ease-in-out forwards;
}

#afford-TOC-face {
  position: relative;
  margin-top: 3%;
  margin-bottom: -20px;
  overflow: hidden;
  height: 500px;
}

#afford-TOC-face-pic {
  width: 100%;
  height: auto;
  position: absolute;
  top: -250px;
  left: 0;
  animation: fadeIn 0.65s ease-in-out forwards;
}

#life-TOC-face {
  position: relative;
  margin-top: 3%;
  margin-bottom: -20px;
  overflow: hidden;
  height: 500px;
}

#life-TOC-face-pic {
  width: 100%;
  height: auto;
  position: absolute;
  top: -200px;
  left: 0;
  animation: fadeIn 0.65s ease-in-out forwards;
}

#prog-TOC-face {
  position: relative;
  margin-top: 3%;
  margin-bottom: -20px;
  overflow: hidden;
  height: 500px;
}

#prog-TOC-face-pic {
  width: 100%;
  height: auto;
  position: absolute;
  top: -300px;
  left: 0;
  animation: fadeIn 0.65s ease-in-out forwards;
}

#tool-TOC-face {
  position: relative;
  margin-top: 3%;
  margin-bottom: -20px;
  overflow: hidden;
  height: 500px;
}

#tool-TOC-face-pic {
  width: 100%;
  height: auto;
  position: absolute;
  top: -200px;
  left: 0;
  animation: fadeIn 0.65s ease-in-out forwards;
}

#about-TOC-face {
  position: relative;
  margin-top: 3%;
  margin-bottom: -20px;
  overflow: hidden;
  height: 500px;
}

#about-TOC-face-pic {
  width: 100%;
  height: auto;
  position: absolute;
  top: -150px;
  left: 0;
  animation: fadeIn 0.65s ease-in-out forwards;
}




.traffic-box-burger {
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 20px;
  width: 600px;
  background-color: #f9f9f9;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin: 5px;
}

.traffic-box-burger h2 {
  font-size: 20px;
  color: #333;
  margin-bottom: 12px;
  font-weight: 600;
  line-height: 1.4;
}

.traffic-box-burger h3 {
  font-size: 14px;
  color: #333;
  margin-bottom: -10px;
  font-weight: 600;
  line-height: 1.4;
  text-align: left;
}

.traffic-box-burger p {
  font-size: 15px;
  margin-bottom: 20px;
  line-height: 1.6;
}

.bottom-half-steps {
  color: rgb(133, 151, 233);
  font-size: 20px;
}

/* Existing Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#Ch-face {
  position: relative;
  width: 100%;
  margin-top: 3%;
  margin-bottom: 1%;
  overflow: hidden;
}

#header-image {
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: cover;
  position: relative;
  top: 0;

  /*animation: fadeIn 0.9s ease-in-out forwards; */
}




.traffic-box-new {
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 20px;
  width: 400px;
  background-color: #f9f9f9;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  margin: 5px;
}

.traffic-box-new h2 {
  font-size: 20px;
  color: #273562 ;
  margin-bottom: 12px;
  font-weight: 600;
  line-height: 1.4;

  text-align: left;
}

.traffic-box-new h3 {
  font-size: 14px;
  color: #273562 ;
  margin-bottom: -10px;
  font-weight: 600;
  line-height: 1.4;

  text-align: left;
}

.traffic-box-new p {
  font-size: 15px;
  
  margin-bottom: 20px;
  line-height: 1.6;

  text-align: left;

  color: #273562 ;
}




















@media (max-width: 1024px) {
  #TOC-face-text {
    font-size: 48px;
  }

  #TOC-introduction-container,
  .traffic-box-new {
    width: 70%; /* Adjust the width as needed */
    margin: 0 auto 30px auto; /* Center horizontally and add bottom space */
  }

  .general-toc-container {
    flex-direction: column;
    align-items: center; /* Center the flex items */
  }

  /* New media query for prep-TOC-face */
  #prep-TOC-face {
    height: 400px;
  }

  #prep-TOC-face-pic {
    top: -200px;
  }

  #TOC-face-text-container {
    padding: 0 10px;
  }

  #TOC-face-text {
    font-size: 40px;
  }

  .traffic-box-new {
    padding: 18px;
    margin-bottom: 30px; /* Add more space at the bottom */
  }

  .traffic-box-new h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .traffic-box-new h3 {
    font-size: 13px;
    margin-bottom: -8px;
  }

  .traffic-box-new p {
    font-size: 14px;
    margin-bottom: 18px;
  }
}

@media (max-width: 768px) {
  #TOC-face-text {
    font-size: 36px;
  }

  #TOC-introduction-container,
  .traffic-box-new {
    width: 80%; /* Adjust the width as needed */
    margin: 0 auto 25px auto; /* Center horizontally and add bottom space */
  }

  .traffic-box-container {
    width: 100%;
    margin: 0 auto 20px auto; /* Center horizontally and add bottom space */
  }

  /* New media query for prep-TOC-face */
  #prep-TOC-face {
    height: 300px;
  }

  #prep-TOC-face-pic {
    top: -150px;
  }

  #TOC-face-text-container {
    padding: 0 10px;
  }

  #TOC-face-text {
    font-size: 32px;
  }

  .traffic-box-new {
    padding: 16px;
    margin-bottom: 25px; /* Add more space at the bottom */
  }

  .traffic-box-new h2 {
    font-size: 16px;
    margin-bottom: 8px;
  }

  .traffic-box-new h3 {
    font-size: 12px;
    margin-bottom: -6px;
  }

  .traffic-box-new p {
    font-size: 13px;
    margin-bottom: 16px;
  }
}


@media (max-width: 390px) {
  #TOC-text-new-face {
    transform: translateY(-20px); /* Moves the div up by 20px */
  }

  .TOC-header-text-new {
    margin-top: -40px;
  }
}


@media (max-width: 480px) {
  #TOC-face-text {
    font-size: 24px;
  }

  #TOC-introduction-container,
  .traffic-box-new {
    width: 90%; /* Adjust the width as needed */
    margin: 0 auto 20px auto; /* Center horizontally and add bottom space */
  }

  .traffic-box-container {
    width: 100%;
    margin: 0 auto 20px auto; /* Center horizontally and add bottom space */
  }

  /* New media query for prep-TOC-face */
  #prep-TOC-face {
    height: 250px;
  }

  #prep-TOC-face-pic {
    top: -100px;
  }

  #TOC-face-text-container {
    padding: 0 5px;
  }

  #TOC-text-new-face {
    font-size: 24px;
    margin-top: -150px;
  }

  .traffic-box-new {
    padding: 14px;
    margin-bottom: 20px; /* Add more space at the bottom */
  }

  .traffic-box-new h2 {
    font-size: 14px;
    margin-bottom: 6px;
  }

  .traffic-box-new h3 {
    font-size: 11px;
    margin-bottom: -4px;
  }

  .traffic-box-new p {
    font-size: 12px;
    margin-bottom: 14px;
  }


  @media (max-width: 480px) {
    /* Adjusting the font sizes for the 480px screen size */
    
    .traffic-box {
      padding: 14px; /* Slightly reduce padding */
    }
  
    .traffic-box-part-number {
      font-size: 12px; /* Reduce the font size for part number */
    }
  
    .traffic-title {
      font-size: 16px; /* Reduce the font size for the title */
      margin-bottom: 10px; /* Adjust the margin below the title */
    }
  
    .traffic-description {
      font-size: 12px; /* Reduce the font size for the description */
      line-height: 1.5; /* Adjust line height for readability */
      margin-bottom: 16px; /* Adjust the margin below the description */
    }
  
    .hidden-box-text {
      font-size: 12px; /* Reduce the font size for hidden text */
      margin-bottom: 16px; /* Adjust the margin below hidden text */
    }
  
    .service-item-button {
      font-size: 14px; /* Reduce the font size for the service button */
      height: 40px; /* Adjust the height of the button */
    }
  
    .traffic-box h2 {
      font-size: 16px; /* Ensure h2 inside traffic-box follows this size */
      margin-bottom: 8px; /* Adjust margin */
    }
  
    .traffic-box h3 {
      font-size: 12px; /* Ensure h3 inside traffic-box follows this size */
      margin-bottom: -4px; /* Adjust margin */
    }
  
    .traffic-box p {
      font-size: 12px; /* Ensure p inside traffic-box follows this size */
      margin-bottom: 14px; /* Adjust margin */
    }

    
  }

  .service-item .service-item-button {
    font-size: 12px !important; /* Force the text size to be 12px */
    
  }
  
}
