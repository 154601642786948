.tool-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f3f6fd; /* Subtle blue-gray background */
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  border-radius: 10px; /* Rounded corners */
}

.tool-title {
  font-size: 3rem; /* Slightly larger for emphasis */
  color: #273562 ; /* Darker, more modern shade */
  margin-bottom: 16px;
  font-weight: bold; /* Bold for better readability */
  /* Uppercase for a more formal look */
  letter-spacing: 1.5px; /* Spacing between letters */

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.tool-message {
  font-size: 1.4rem; /* Slightly larger for readability */
  color: #34495e; /* Darker shade for better contrast */
  margin-bottom: 30px;
  max-width: 600px; /* Limit width for better readability */
  line-height: 1.6; /* Increased line height for readability */

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.tool-link {
  font-size: 1.3rem; /* Slightly larger for emphasis */
  color: #ffffff;
  background-color: #4f46e5;
  text-decoration: none;
  
  padding: 12px 24px; /* Slightly larger padding for emphasis */
  border-radius: 50px; /* Fully rounded button */
  transition: background-color 0.3s, color 0.3s, transform 0.3s; /* Added transition for transform */
  font-weight: bold; /* Bold for better readability */

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.tool-link:hover {
  background-color: #3630a0; /* Darker blue on hover */
  color: #ffffff;
  
  
}




/* For screens 1024px and below */
@media (max-width: 1024px) {
  .tool-container {
    padding: 15px;
    height: auto;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .tool-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }

  .tool-message {
    font-size: 1.3rem;
    margin-bottom: 25px;
  }

  .tool-link {
    font-size: 1.2rem;
    padding: 10px 22px;
  }
}

/* For screens 768px and below */
@media (max-width: 768px) {
  .tool-container {
    padding: 10px;
    height: auto;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .tool-title {
    font-size: 2rem;
    margin-bottom: 18px;
  }

  .tool-message {
    font-size: 1.2rem;
    margin-bottom: 20px;
    max-width: 500px;
  }

  .tool-link {
    font-size: 1.1rem;
    padding: 8px 20px;
  }
}

/* For screens 480px and below */
@media (max-width: 480px) {
  .tool-container {
    padding: 8px;
    height: auto;
    box-shadow: none; /* Remove shadow for smaller screens */
    border-radius: 5px; /* Slightly less rounded corners */
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .tool-title {
    font-size: 1.4rem; /* Slightly reduced */
    margin-bottom: 15px;
  }

  .tool-message {
    font-size: 0.8rem; /* Further reduced */
    margin-bottom: 15px;
    max-width: 80%; /* Ensure full width */
  }

  .tool-link {
    font-size: 0.8rem; /* Slightly reduced */
    padding: 6px 18px;
  }
}