@import url("https://fonts.googleapis.com/css?family=Jost:400,500,600");
@import url("https://fonts.googleapis.com/css?family=Kalam");
@import url("https://fonts.googleapis.com/css?family=Sniglet");

body {
  font-family: "Jost", sans-serif;
}

.side-navbar {
  position: fixed;
  top: 120px; /* Aligns with your content, can be adjusted */
  left: 20px; /* Align with the left edge with a margin */
  background: #233653; /* Same background color as regular navbar */
  width: 220px; /* Slightly reduced width */
  height: auto; /* Maintain dynamic height */
  max-height: 80vh; /* Limit the total height to 80% of the viewport */
  z-index: 5; /* Matches the regular navbar z-index */
  padding: 15px 0; /* Consistent padding */
  border-right: 2px solid rgba(227, 229, 233, 0.2); /* Add a border to match styling */
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.2); /* Retain the box-shadow */
  overflow-y: hidden; /* Remove scrolling */
  border-radius: 15px; /* Rounded edges */
  transition: all 0.3s ease;
}

.side-navbar-middle {
  display: flex;
  flex-direction: column;
  padding-left: 15px; /* Adjusted padding to match regular navbar style */
  padding-right: 15px; /* Adjusted padding to match regular navbar style */
}

.side-navbar-category {
  margin-bottom: 10px; /* Reduced margin */
  font-size: 16px; /* Smaller font size */
  color: #e3e5e9;
  text-decoration: none;
  position: relative;
}

.side-navbar-category-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px; /* Adjusted padding */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  border-radius: 8px;
  font-weight: 500;

  
  margin-bottom: 10px;
}

.side-navbar-category-header:hover {
  background-color: #284B63; /* Matches hover color from the regular navbar */
  transform: scale(1.02); /* Slightly reduced scale to match regular navbar */
}

.side-navbar-toggle {
  background: none;
  border: none;
  color: #e3e5e9;
  font-size: 16px; /* Adjusted font size */
  cursor: pointer;
}

.side-navbar-link {
  color: inherit;
  text-decoration: none;
  flex-grow: 1;
  font-weight: 600;
}

.side-navbar-dropdown-content,
.side-navbar-submenu-content {
  display: none;
  background-color: #5d7685; /* Lighter grey background for content */
  padding-left: 8px;
  padding-right: 8px;
  border-left: none; /* Removed the border that might cause shininess */
  border-radius: 8px;
  padding-top: 6px; /* Adjusted padding */
  padding-bottom: 6px; /* Adjusted padding */
  box-shadow: none; /* Removed box-shadow to eliminate shininess */
}

.side-navbar-dropdown-link,
.side-navbar-submenu-link {
  color: #e3e5e9;
  padding: 6px 8px; /* Adjusted padding */
  text-decoration: none;
  display: block;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 5px;
  font-size: 14px; /* Smaller font size */
  margin-top: 6px; /* Reduced margin */
  margin-bottom: 6px; /* Reduced margin */
}

.side-navbar-dropdown-link:hover,
.side-navbar-submenu-link:hover,
.scholarship-header:hover,
.active {
  background-color: #2f3f49; /* Matches hover color from the regular navbar */
  color: #fff;
  border-radius: 5px;
}

.side-navbar-category.open .side-navbar-dropdown-content,
.side-navbar-category.open .side-navbar-submenu-content {
  display: block;
}

@keyframes dropdown-open {
  0% {
    max-height: 0;
    opacity: 0;
  }
  100% {
    max-height: 200px;
    opacity: 1;
  }
}

/* Hide the sidebar on mobile and tablet screens */
@media (max-width: 1024px) {
  .side-navbar {
    display: none;
  }
}
