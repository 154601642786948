@import url("https://fonts.googleapis.com/css?family=Jost");
@import url("https://fonts.googleapis.com/css?family=Kalam");
@import url("https://fonts.googleapis.com/css?family=Sniglet");



@import url("https://fonts.googleapis.com/css?family=Open Sans");

@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css?family=Raleway");

#navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 5; /* Ensure this is higher than dropdown-content */
  /*background: #353535;*/

  background: #161f40;
  height: 7%;
  width: 100%;

  
}


#left-nav {
  position: absolute;
  display: flex;
  flex-direction: row;
  left: 40px;
  top: 2px;
  z-index: 1;
  width: 60px;
  height: 60px;
}

#logo-pic {
  margin-top: 4px;
  width: 100%;
  aspect-ratio: 1.15;
  object-fit: contain;
}

#mid-nav {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  left: 270px;
}

#right-nav {
  position: relative;
  left: 220px;

  margin-right: 40px;
}

#right-nav-responsive {
  position: absolute;
  display: none; /* Default to hidden */
  flex-direction: row;
  right: 20px; /* Adjust the right positioning as needed */
  width: 25px; /* Adjust the width */
  height: 20px; /* Adjust the height */
  z-index: 3; /* Ensure it appears above other elements */
  justify-content: center;
  align-items: center;
}



.mid-category {
  margin-left: 15px;
  margin-right: 15px;
  font-size: 16px;
  
  color: rgb(227, 229, 233);
  text-decoration: none;
  position: relative;
  
  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.mid-category a {
  color: inherit;
  text-decoration: none;
}

.mid-category:hover {
  cursor: pointer;

  /*
  transform: scale(1.02);
  transition-duration: 0.4s;

  */
}

/* Adjust the z-index for dropdown-content to be less than the navbar */
.dropdown-content,
.dropdown-sub-content {
  display: none;
  position: absolute;
  background-color: #2c366c;
  min-width: 150px; /* Adjusted width */
  z-index: 2; /* Set lower than navbar */
  padding: 10px 12px; /* Adjusted padding */
  top: 100%;
  left: -14%;
  border-radius: 4px;

  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.dropdown-sub-content {
  top: 0;
  left: 100%;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-content a,
.dropdown-sub-content a {
  color: rgb(227, 229, 233);
  padding: 8px 10px; /* Adjusted padding */
  text-decoration: none;
  display: block;
  font-size: 14px; /* Adjusted font size */

  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.dropdown-content a:hover,
.dropdown-sub-content a:hover {
  background-color: #1d2749 ;
  
  border-radius: 4px; /* Optional: adds rounded corners */
}



.mid-category:hover .dropdown-content {
  display: block;
  
}

.dropdown-content .has-submenu:hover .dropdown-sub-content {
  display: block;
  
}

.dropdown-sub-content {
  top: 0;
  left: 100%;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-style: normal;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  display: none;
  visibility: hidden;
}

.bar {
  display: block;
  position: relative;
  cursor: pointer;
  width: 25px;
  height: 20px;
}

.bar span {
  position: absolute;
  width: 22.5px;
  height: 3.5px;
  background: #f1faee;
  border-radius: 100px;
  display: inline-block;
  transition: 0.3s ease;
  left: 0;
}

.bar span.top {
  top: 0;
}

.bar span.middle {
  top: 8.5px;
}

.bar span.bottom {
  bottom: 0;
}

input[type="checkbox"]:checked ~ span.top {
  transform: rotate(45deg);
  transform-origin: top left;
  width: 24px;
  left: 2.5px;
}

input[type="checkbox"]:checked ~ span.bottom {
  transform: rotate(-45deg);
  transform-origin: top left;
  width: 24px;
  bottom: -0.5px;
  box-shadow: 0 0 5px #495057;
}

input[type="checkbox"]:checked ~ span.middle {
  transform: translateX(-20px);
  opacity: 0;
}

#dropdown-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #2c366c ;
  overflow: hidden;
  z-index: 1; /* Set lower than navbar */
  height: 0;
  transition: height 0.5s ease-in-out;
}

#dropdown-menu.open {
  height: 100vh; /* Adjusted height */

  
}

#dropdown-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

#dropdown-menu ul li {
  text-align: center;
  margin: 10px 0;
}

#dropdown-menu ul li a {
  color: #e3e5e9;
  text-decoration: none;
  font-size: 18px; /* Adjusted font size */
  font-family: 'Poppins', sans-serif;
  
  font-style: normal;

  background-color: #1d2749 ;
}

#dropdown-menu .close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: #e3e5e9;
  cursor: pointer;
}

.logout-btn {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 6px;
  padding-bottom: 6px;

  margin-right: 10px;

  border-radius: 6px;
  font-size: 16px;

  background: #4f46e5;
  border: 0px;

  color: white ;
  
  
  text-decoration: none;
  position: relative;
  
  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.logout-btn:hover {
  background: white;

  color: #3630a0 ;
  cursor: pointer;

  

  transition: background-color .1s ease-in-out;
}












.login-btn {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 6px;
  padding-bottom: 6px;

  border-radius: 6px;
  font-size: 16px;

  background: #4f46e5;

  color: white ;
  
  
  text-decoration: none;
  position: relative;
  
  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.login-btn:hover {
  background: white;

  color: #3630a0 ;

  

  transition: background-color .1s ease-in-out;
}













/* Hide mid-nav and show right-nav-responsive on mobile and tablet screens */
@media (max-width: 1024px) {
  #mid-nav {
    display: none;
  }

  #logo-pic {
    width: 80%;
  }

  #navbar {
    height: 6%;
  }

  #right-nav {
    display: none;
  }

  #right-nav-responsive {
    display: flex;
    right: 25px;
    width: 25px;
    height: 20px;
  }

  .mid-category {
    font-size: 20px; /* Increased font size */
    margin-bottom: 10px; /* Added bottom margin for spacing */
    outline: none; /* Remove blue box on focus */
    -webkit-tap-highlight-color: transparent; /* For mobile browsers */
  }

  #dropdown-menu ul li a {
    font-size: 20px; /* Increased font size */
    background: none; /* Removed background */
    border: none; /* Removed border */
    padding: 8px 12px; /* Adjusted padding */
  }

  .bar span {
    width: 20px;
    height: 3px;
  }

  .bar span.middle {
    top: 7px;
  }

  .login-btn {
    background: #4f46e5;
  }
  
  .login-btn:hover {
    color: #3630a0;
    transition: background-color 0.1s ease-in-out;
  }

  .logout-btn {
   margin-right: 0px;
  }
  
}

/* Responsive Design for 768px */
@media (max-width: 768px) {
  #logo-pic {
    width: 80%;
  }

  #right-nav {
    display: none;
  }

  #navbar {
    height: 6%;
  }

  .mid-category {
    font-size: 18px; /* Increased font size */
    outline: none; /* Remove blue box on focus */
    -webkit-tap-highlight-color: transparent; /* For mobile browsers */
  }

  #dropdown-menu ul li a {
    font-size: 18px; /* Increased font size */
    background: none; /* Removed background */
    border: none; /* Removed border */
    padding: 8px 12px; /* Adjusted padding */
  }

  #dropdown-menu.open {
    height: 100vh; /* Adjusted height */
  }

  .login-btn {
    background: #4f46e5;
  }
  
  .login-btn:hover {
    color: #3630a0;
    transition: background-color 0.1s ease-in-out;
  }

  .logout-btn {
    margin-right: 0px;
   }
   
}

/* Responsive Design for 480px */
@media (max-width: 480px) {
  #logo-pic {
    width: 80%;
  }

  #navbar {
    height: 6%;
  }

  #right-nav {
    display: none;
  }

  .mid-category {
    font-size: 16px; /* Increased font size */
    outline: none; /* Remove blue box on focus */
    -webkit-tap-highlight-color: transparent; /* For mobile browsers */
  }

  #dropdown-menu ul li a {
    font-size: 16px; /* Increased font size */
    background: none; /* Removed background */
    border: none; /* Removed border */
    padding: 8px 12px; /* Adjusted padding */
  }

  #right-nav-responsive {
    right: 25px;
    width: 20px;
    height: 18px;
  }

  .bar span {
    width: 20px;
    height: 3px;
  }

  .bar span.middle {
    top: 7px;
  }

  #dropdown-menu.open {
    height: 100vh; /* Adjusted height */
  }

  .login-btn {
    background: #4f46e5;
  }
  
  .login-btn:hover {
    color: #3630a0;
    transition: background-color 0.1s ease-in-out;
  }

  .logout-btn {
    margin-right: 0px;
   }

  
   
}
