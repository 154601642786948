@import url("https://fonts.googleapis.com/css?family=Jost");
@import url("https://fonts.googleapis.com/css?family=Lato");

@import url("https://fonts.googleapis.com/css?family=Open Sans");

@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css?family=Raleway");

#about-background {
  background-color: #f3f6fd;

  padding-bottom: 2%;

  
}

#about-face {
  position: relative;
  margin-top: 3%;
  text-align: center;
}

#about-pic {
  height: auto;
  max-height: 500px;
  width: 100%;
  z-index: 0.9;
}

#about-face-text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

#about-face-text {
  font-size: 64px;
  font-family: "Lato", sans-serif;
  color: white;
  animation: fadeIn 2s ease;
}

#about-story-container {
  width: 60%;
  margin: 50px auto;
  padding: 30px;
  text-align: center;
  font-size: 18px;
  font-family: "Jost", sans-serif;
  color: #ffffff;
  background: #284B63;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: box-shadow 0.3s ease-in-out;
}

#about-story-container:hover {
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}

#about-story-top {
  margin-bottom: 20px;
}

#about-story-title {
  font-size: 36px;
  font-family: "Lato", sans-serif;
  color: #ffffff;
}

#about-story-title-underline {
  height: 4px;
  width: 50px;
  margin: 10px auto;
  background: #ffffff;
  border-radius: 2px;
}

#about-story-text {
  font-size: 16px;
  color: #ffffff;
}

#team-container {
  display: flex;
  flex-direction: row;
  justify-content: center; /* Align items to the start */
  flex-wrap: wrap;
  margin: 50px auto;
  width: 100%;
}

.team-member-container {
  display: flex;
  align-items: center; /* Vertically center content */
  justify-content: center; /* Horizontally center content within the container */
  width: 40%; /* Reduced width for smaller containers */
  margin: 20px; /* Adjusted margin for spacing */
  padding: 20px;
  background: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: box-shadow 0.3s ease-in-out;
  box-sizing: border-box; /* Ensures padding is included in the total width/height */
}


.team-member-container:hover {
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}

.about-pic-container {
  flex: 0 0 30%; /* Picture takes 30% of the width */
  margin-right: -20px; /* Reduced space between picture and text */
  display: flex;
  align-items: center; /* Center image vertically */
  justify-content: center; /* Center image horizontally */
}


.profile-picture {
  height: 140px; /* Reduced height */
  width: 140px; /* Reduced width to keep circular shape */
  border-radius: 50%; /* Makes the image a circle */
  object-fit: cover; /* Ensures the image covers the circle without distortion */
}



.about-description-container {
  flex: 1; /* Text takes the remaining width */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center content */
  padding: 20px;
  text-align: left;
  align-items: center; /* Center text content horizontally */

  color: #273562 ;
}



.about-position {
  
  margin-bottom: 10px; /* Add space below the position */
  font-size: 22px;
  font-family: "Lato", sans-serif;
  color: #273562;

  line-height: 1.75;;
}

.name {
  margin: 0;
  margin-bottom: 10px; /* Add space below the name */
  font-size: 28px;
  
  color: #273562 ;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.about-text-paragraph {
  margin: 0;
  margin-top: 20px; /* Add more space above the text paragraph */
  font-size: 18px;
  
  color: #273562;
  line-height: 2.5;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-style: normal;
  
}

.linkedin-link {
  margin-left: 10px;
}

.linkedin-image {
  width: 44px;
  vertical-align: middle;
  margin-top: 20px; /* Adjusted margin */
}




.name, .about-position, .about-text-paragraph {
  margin: 0;
}

















/* Media Query for 1024px screens */
@media (max-width: 1024px) {
  #about-face-text {
      font-size: 52px;
  }

  #about-story-container {
      width: 70%;
      font-size: 16px;
      padding: 20px;
  }

  #about-story-title {
      font-size: 32px;
  }

  #about-story-title-underline {
      width: 40px;
  }

  #about-story-text {
      font-size: 14px;
  }

  .team-member-container {
      width: 45%;
  }

  .profile-picture {
      height: 120px;
      width: 120px;
  }

  .about-position {
      font-size: 20px;
  }

  .name {
      font-size: 24px;
  }

  .about-text-paragraph {
      font-size: 16px;
      line-height: 2;
      width: 90%;  /* Smaller width */
      text-align: center;  /* Center align text */
      margin: 0 auto;  /* Center align container */
      margin-top: 10px;
  }

  .linkedin-image {
      width: 36px;
  }

  #table-content-container-subs {
      margin-bottom: 4%;
      margin-top: 3%;
  }

  #smaller-toc-cont-subs {
      width: 50%;
  }

  #new-TOC-name-subs {
      font-size: 50px;
  }

  #new-toc-description-subs {
      width: 60%;
      font-size: 18px;
  }
}

/* Media Query for 768px screens */
@media (max-width: 768px) {
  #about-face-text {
      font-size: 44px;
  }

  #about-story-container {
      width: 80%;
      font-size: 14px;
      padding: 15px;
  }

  #about-story-title {
      font-size: 28px;
  }

  #about-story-title-underline {
      width: 35px;
  }

  #about-story-text {
      font-size: 13px;
  }

  .team-member-container {
      width: 80%;
      margin: 15px 0;
  }

  .profile-picture {
      height: 100px;
      width: 100px;
  }

  .about-position {
      font-size: 18px;
  }

  .name {
      font-size: 22px;
  }

  .about-text-paragraph {
      font-size: 14px;
      line-height: 1.8;
  }

  .about-description-container {
      width: 90%;  /* Less wide */
  }

  .linkedin-image {
      width: 32px;
  }

  #table-content-container-subs {
      margin-bottom: 3%;
      margin-top: 3%;
  }

  #smaller-toc-cont-subs {
      width: 60%;
  }

  #new-TOC-name-subs {
      font-size: 40px;
  }

  #new-toc-description-subs {
      width: 70%;
      font-size: 16px;
  }
}

/* Media Query for 480px screens */
@media (max-width: 480px) {
  #about-face-text {
      font-size: 36px;
  }

  #about-story-container {
      width: 90%;
      font-size: 12px;
      padding: 10px;
  }

  #about-story-title {
      font-size: 24px;
  }

  #about-story-title-underline {
      width: 30px;
  }

  #about-story-text {
      font-size: 12px;
  }

  .team-member-container {
      width: 75%;
      margin: 10px 0;
      padding: 15px;
  }

  .profile-picture {
      height: 80px;
      width: 80px;
  }

  .about-pic-container {
      margin-right: -10px;
  }

  .about-position {
      font-size: 16px;
  }

  .name {
      font-size: 20px;
  }

  .about-text-paragraph {
      font-size: 12px;
      line-height: 1.6;
  }

  .about-description-container {
      width: 95%;  /* Less wide */
  }

  .linkedin-image {
      width: 28px;
  }

  #table-content-container-subs {
      margin-bottom: 2%;
      margin-top: 3%;
  }

  #smaller-toc-cont-subs {
      width: 80%;
  }

  #new-TOC-name-subs {
      font-size: 32px;
  }

  #new-toc-description-subs {
      width: 80%;
      font-size: 14px;
  }
}
