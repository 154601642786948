/* LoginPrompt.css */

@import url("https://fonts.googleapis.com/css?family=Open Sans");

@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css?family=Raleway");

.login-prompt-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    text-align: center;
    background-color: #f4f4f4;
    padding: 20px;

    font-family: 'Poppins', sans-serif;
    
    font-style: normal;
  }
  
  .login-prompt-title {
    font-size: 28px;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  .login-prompt-message {
    font-size: 18px;
    color: #7f8c8d;
    margin-bottom: 20px;
  }
  
  .login-button {
    padding: 12px 24px;
    font-size: 16px;
    color: #ffffff;
    background-color: #4f46e5;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background-color: #3630a0;
  }
  















  /* Responsive Design for 1024px */
@media (max-width: 1024px) {
  .login-prompt-title {
      font-size: 26px; /* Slightly reduced font size */
      margin-bottom: 8px; /* Adjusted margin */
  }

  .login-prompt-message {
      font-size: 16px; /* Slightly reduced font size */
      margin-bottom: 18px; /* Adjusted margin */
  }

  .login-button {
      padding: 10px 22px; /* Adjusted padding */
      font-size: 15px; /* Slightly reduced font size */
  }
}

/* Responsive Design for 768px */
@media (max-width: 768px) {
  .login-prompt-title {
      font-size: 24px; /* Further reduced font size */
      margin-bottom: 6px; /* Adjusted margin */
  }

  .login-prompt-message {
      font-size: 15px; /* Further reduced font size */
      margin-bottom: 16px; /* Adjusted margin */
  }

  .login-button {
      padding: 8px 20px; /* Further adjusted padding */
      font-size: 14px; /* Further reduced font size */
  }
}

/* Responsive Design for 480px */
@media (max-width: 480px) {
  .login-prompt-title {
      font-size: 22px; /* Reduced font size for small screens */
      margin-bottom: 5px; /* Adjusted margin */
  }

  .login-prompt-message {
      font-size: 16px; /* Reduced font size for small screens */
      margin-bottom: 14px; /* Adjusted margin */
  }

  .login-button {
      padding: 6px 20px; /* Reduced padding for small screens */
      font-size: 13px; /* Reduced font size for small screens */
  }
}