@import url("https://fonts.googleapis.com/css?family=Open Sans");

@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css?family=Raleway");


/* Container Styling */
.sub-nav-container {
   /* Light grey to dark grey gradient */
  width: 100%;
  max-width: 59%;
  height: 50px;
  text-align: left;
  margin: 0 auto 60px;
  padding: 0 20px; /* Adjusted padding for better centering */
  
  color: #a8a7a7; /* Dark grey text color */

  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-style: normal;
  
  display: flex; /* Flexbox for centering */
  align-items: center; /* Center content vertically */
  
  border-bottom: 3px solid #373c5e; /* Bottom border with a medium grey color */
}

/* Breadcrumb Styling */
.main-sub-nav,
.smaller-sub-nav {
  display: flex;
  align-items: center;
  font-size: 18px;
}

.main-sub-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
 
  font-size: 26px;
  color: #2c2c2c; /* Slightly darker grey text color */
}

.sub-nav-item {
  color: #2c366c; /* Medium grey text color */
  text-decoration: none;
  margin: 0 8px;
  transition: color 0.1s ease, transform 0.1s ease;
}

/* Active Breadcrumb Styling */
.sub-nav-item.active {
  color: #212121; /* Darker grey color for active path */
}

.sub-nav-item:hover,
.sub-nav-item:focus {
  color: #1c1c1c; /* Slightly darker grey on hover/focus */
  transform: none; /* No transform effect */
}

.sub-nav-slash {
  margin: 0 8px;
  color: #273562 ; /* Slightly darker grey text color */
}






/* Responsive Design */
@media (max-width: 1024px) {
  .sub-nav-container {
    height: 60px;
    width: 85%; /* Slightly wider */
    padding: 0 15px; /* Adjusted padding */
    font-size: 14px; /* Reduced font size */
  }
  .main-sub-nav {
    font-size: 20px; /* Reduced font size */
  }
}

@media (max-width: 768px) {
  .sub-nav-container {
    height: 70px;
    width: 90%; /* Adjusted width */
    padding: 0 15px; /* Adjusted padding */
    font-size: 10px; /* Further reduced font size */
  }
  .main-sub-nav {
    font-size: 16px; /* Further reduced font size */
  }
  .sub-nav-item {
    margin: 0 8px;
  }
  .sub-nav-slash {
    margin: 0 8px.
  }
}

@media (max-width: 480px) {
  .sub-nav-container {
    display: none;
  }
  .main-sub-nav {
    font-size: 20px; /* Slightly larger font */
  }
  .sub-nav-item {
    margin: 0 6px;
  }
  .sub-nav-slash {
    margin: 0 6px;
  }
}
