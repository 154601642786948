/* General Styles */
body {
  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
  margin: 0;
  padding: 0;
  background-color: #d9d9d9;
}

.contact-container {
  max-width: 800px;
  margin-top: 3%;
  margin-bottom: 2%;
  margin-right: auto;
  margin-bottom: 50px;
  margin-left: auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.title {
  color: #333;
}

.introText {
  line-height: 1.6;
  color: #666;

  font-family: 'Poppins', sans-serif;
 
  font-style: normal;
}

.subTitle {
  color: #333;
  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.contactInfo {
  line-height: 1.6;
  color: #666;
}

.link {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #007bff;
  text-decoration: none;
}

.link:hover {
  cursor: pointer;
  transform: scale(1.02);
  color: #1abc9c;
}

/* Contact Form */
.form {
  display: flex;
  flex-direction: column;
}

.full_name {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.input-group {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.contact_input,
.textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box; /* Ensure padding is included in the element's width */

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.textarea {
  height: 120px;
}

.contact_button {
  cursor: pointer;
  transition: all 0.3s;
  background-color: #4f46e5; /* bg-blue-500 */
  color: white;
  padding: 0.5rem 1.5rem; /* px-6 py-2 */
  border-radius: 0.5rem; /* rounded-lg */
  
  border-width: 0px;
  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}


.contact_button:hover {
  background: #3630a0;
 
}

.contact_button:active {
  filter: brightness(0.90); /* active:brightness-90 */
  transform: translateY(2px); /* active:translate-y-[2px] */
  border-bottom-width: 2px; /* active:border-b-[2px] */
}

/* Social Media Links */
.socialMediaList {
  list-style-type: none;
  padding: 0;
}

.socialMediaListItem {
  display: inline;
  margin-right: 10px;
}

.socialMediaLink {
  text-decoration: none;
  color: #007bff;
}

.socialMediaLink:hover {
  color: #0056b3;
  text-decoration: underline;
}

/* FAQs and Help Center */
.faqLink,
.helpCenterLink {
  color: #007bff;
}

.faqLink:hover,
.helpCenterLink:hover {
  color: #0056b3;
  text-decoration: underline;
}

.successMessage {
  color: #4caf50; /* Green color to indicate success */
  background-color: #e8f5e9; /* Light green background */
  border: 1px solid #4caf50; /* Green border */
  padding: 15px; /* Some padding */
  border-radius: 5px; /* Rounded corners */
  font-size: 18px; /* Larger font size */
  text-align: center; /* Center the text */
  margin-top: 200px; /* Space above the message */
  margin-bottom: 200px; /* Space below the message */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  max-width: 600px; /* Limit the width */
  margin-left: auto; /* Center horizontally */
  margin-right: auto; /* Center horizontally */
}




#contact-body {
  background-color: #f3f6fd;

  padding-bottom: 2%;
  padding-top: 3%;
}















/* Media Queries */

/* For screens of width 1024px */
@media screen and (max-width: 1024px) {
  .contact-container {
    max-width: 80%;
    padding: 15px;
  }

  .title, .subTitle {
    font-size: 24px;
  }

  .introText, .contactInfo {
    font-size: 16px;
  }

  .label {
    font-size: 16px;
  }

  .contact_input, .textarea {
    font-size: 14px;
  }

  .contact_button {
    padding: 0.4rem 1.2rem;
    font-size: 14px;
  }

  #contact-body {
    
    padding-top: 5%;
  }
}

/* For screens of width 768px */
@media screen and (max-width: 768px) {
  .contact-container {
    max-width: 85%;
    padding: 15px;
  }

  .title, .subTitle {
    font-size: 20px;
  }

  .introText, .contactInfo {
    font-size: 14px;
  }

  .label {
    font-size: 14px;
  }

  .contact_input, .textarea {
    font-size: 12px;
  }

  .contact_button {
    padding: 0.4rem 1rem;
    font-size: 12px;
  }

  #contact-body {
    
    padding-top: 7%;
  }

}

/* For screens of width 480px */
@media screen and (max-width: 480px) {
  body {
    font-size: 14px;
  }

  .contact-container {
    max-width: 85%;
    padding: 10px;

    
  }

  .title, .subTitle {
    font-size: 18px;
  }

  .introText, .contactInfo {
    font-size: 12px;
    line-height: 1.4;
  }

  .label {
    font-size: 12px;
  }

  .contact_input, .textarea {
    font-size: 12px;
    padding: 8px;
  }

  .contact_button {
    padding: 0.3rem 0.8rem;
    font-size: 12px;
  }

  .successMessage {
    font-size: 16px;
    padding: 10px;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  #contact-body {
    
    padding-top: 12%;
  }
}
