@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&family=Open+Sans:wght@300;400;600&display=swap');
@import url("https://fonts.googleapis.com/css?family=Open Sans");

@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css?family=Raleway");

:root {
  --primary-color: #2c3e50;
  --secondary-color: #34495e;
  --text-color: #333;
  --background-color: #f9f9f9;
  --accent-color: #3498db;
  --border-radius: 8px;
}

.privacy-policy-container, .terms-of-service-container {
  max-width: 900px;
  margin: 40px auto;
  margin-top: 50px;
  padding: 1px 40px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius);
  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
  line-height: 1.8;
  color: var(--text-color);
}

.privacy-policy-title, .terms-of-service-title {
  font-family: 'Raleway', sans-serif;
  
  font-style: normal;
  font-size: 2.8em;
  color: var(--primary-color);
  margin-bottom: 20px;
  text-align: center;
  font-weight: 700;
}

.privacy-policy-date, .terms-of-service-date {
  font-style: italic;
  color: #7f8c8d;
  text-align: center;
  margin-bottom: 40px;
  font-weight: 300;
}

.privacy-policy-intro, .terms-of-service-intro {
  font-size: 1.1em;
  margin-bottom: 40px;
  text-align: justify;
  font-weight: 300;
}

.privacy-policy-section, .terms-of-service-section {
  margin-bottom: 40px;
  padding: 25px;
  background-color: var(--background-color);
  border-radius: var(--border-radius);
  transition: box-shadow 0.3s ease;
}

.privacy-policy-section:hover, .terms-of-service-section:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.privacy-policy-heading, .terms-of-service-heading {
  font-family: 'Poppins', sans-serif;
  
  font-style: normal;

  font-size: 1.6em;
  color: var(--secondary-color);
  margin-bottom: 20px;
  border-bottom: 2px solid var(--accent-color);
  padding-bottom: 10px;
  font-weight: 400;
}

.privacy-policy-list, .terms-of-service-list {
  padding-left: 20px;
}

.privacy-policy-item, .terms-of-service-item {
  margin-bottom: 15px;
  position: relative;
}

.privacy-policy-item::before, .terms-of-service-item::before {
  content: '•';
  color: var(--accent-color);
  font-weight: bold;
  position: absolute;
  left: -20px;
}

.privacy-policy-text, .terms-of-service-text {
  margin-bottom: 20px;
  text-align: justify;
}

.privacy-policy-email, .terms-of-service-email {
  color: var(--accent-color);
  text-decoration: none;
  transition: color 0.3s ease;
  font-weight: 600;
}

.privacy-policy-email:hover, .terms-of-service-email:hover {
  color: var(--primary-color);
  text-decoration: underline;
}



.privacy-background-page {
  background: #f3f6fd;

  padding-bottom: 2%;
}

















/* Media Queries */

/* For screen size 1024px */
@media (max-width: 1024px) {
  .privacy-policy-container, .terms-of-service-container {
    padding: 1px 30px;
    width: 80%;
  }

  .privacy-policy-title, .terms-of-service-title {
    font-size: 2.4em;
  }

  .privacy-policy-intro, .terms-of-service-intro {
    font-size: 1em;
  }

  .privacy-policy-heading, .terms-of-service-heading {
    font-size: 1.4em;
  }

  .privacy-policy-section, .terms-of-service-section {
    padding: 20px;
  }
}

/* For screen size 768px */
@media (max-width: 768px) {
  .privacy-policy-container, .terms-of-service-container {
    padding: 1px 20px;
    width: 80%;
  }

  .privacy-policy-title, .terms-of-service-title {
    font-size: 2em;
  }

  .privacy-policy-intro, .terms-of-service-intro {
    font-size: 0.9em;
  }

  .privacy-policy-heading, .terms-of-service-heading {
    font-size: 1.2em;
  }

  .privacy-policy-section, .terms-of-service-section {
    padding: 15px;
  }
}

/* For screen size 480px */
@media (max-width: 480px) {
  .privacy-policy-container, .terms-of-service-container {
    padding: 1px 15px;
    width: 80%;
  }

  .privacy-policy-title, .terms-of-service-title {
    font-size: 1.8em;
  }

  .privacy-policy-intro, .terms-of-service-intro {
    font-size: 0.8em;
  }

  .privacy-policy-heading, .terms-of-service-heading {
    font-size: 1em;
  }

  .privacy-policy-section, .terms-of-service-section {
    padding: 10px;
  }

  .privacy-policy-date, .terms-of-service-date {
    margin-bottom: 30px;
  }
}
