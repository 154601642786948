@import url("https://fonts.googleapis.com/css?family=Jost");
@import url("https://fonts.googleapis.com/css?family=Lato");

@import url("https://fonts.googleapis.com/css?family=Open Sans");

@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css?family=Raleway");

#Footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #161f40 ;
  color: white;
  padding-top: 2%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

#footer-left-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 85%;
  margin-bottom: .85%;
  
  margin-top: -.25%;

  
}

#footer-college {
  font-size: 36px;
  margin-bottom: -5px;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.footer-category-title {
  margin-bottom: 15px;
  font-size: 16px;

  
  font-family: 'Raleway', sans-serif;
  
  font-style: normal;
}

#footer-top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  margin-bottom: -2.5%;

  
  
}

.footer-category-new {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  width: 35%;


}

.footer-category-title-new {
  font-size: 18px;
  font-family: "Lato";
  
  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.footer-category,
.footer-category-3 {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  
}

.footer-category-item {
  margin: 5px 0;
  font-size: 16px;
  font-family: "Jost";
  text-decoration: none;
  color: #ffffff;
}

.footer-category-item:hover {
  cursor: pointer;
  transform: scale(1.15);
}

.footer-category-item-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
}


.footer-category-pic {
  max-width: 40px;
  max-height: 40px;
  object-fit: contain;
}


.footer-category-pic-two {
  max-width: 30px;
  max-height: 40px;
  object-fit: contain;
  margin-right: 20px;
}

#important-part-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 82.5%;
  margin-top: 3.5%;
  font-size: 13px;
  font-family: "Jost";
  border-top: 2px solid white;
  padding: 20px;
  box-sizing: border-box;
}

#privacy-container {
  display: flex;
  flex-direction: row;
}

.privacy-item {
  margin: 0px 5px 0;
  text-decoration: none;
  color: #ffffff;
}

.privacy-item:hover {
  cursor: pointer;
  transform: scale(1.05);
}

#disclaimer {
  text-align: center;
  box-sizing: border-box;
}

#copyright {
  text-align: center;
  box-sizing: border-box;
}



#new-privacy-bottom-last-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
  width: 100%;
  margin-top: 1%;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.right-below-bar-footer {
  font-family: 'Poppins', sans-serif;
  
  font-style: normal;

  font-size: 14px;
}

.footer-category-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-category-title-3 {
  margin-bottom: 6px;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  
  font-style: normal;

  
  /*
  background: rgb(88, 127, 163);
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 3px;
  padding-top: 3px;

  border-radius: 10px;
 */
  color: white;
}



.footer-category-title-last {
  margin-bottom: 6px;
  font-size: 18px;

  font-family: 'Raleway', sans-serif;
  
  font-style: normal;
  
  
}





















@media (max-width: 1024px) {
  #Footer {
      padding-top: 1.5%;
  }

  #footer-college {
      font-size: 32px; /* Slightly smaller for medium screens */
  }

  .footer-category-title, .footer-category-title-new, .footer-category-title-3 {
      font-size: 15px;
  }

  .footer-category-title-last {
      font-size: 16px;
  }

  #footer-top-container {
      width: 90%;
  }

  #important-part-container {
      width: 90%;
      font-size: 12px;
  }

  #new-privacy-bottom-last-container {
      font-size: 13px;
  }
}

@media (max-width: 768px) {
  #footer-college {
      font-size: 28px; /* Further reduced size */
      text-align: center;
  }

  #footer-left-container {
      align-items: center;
      text-align: center;
  }

  .footer-category-title, .footer-category-title-new, .footer-category-title-3 {
      font-size: 14px;
  }

  .footer-category-title-last {
      font-size: 15px;
  }

  #footer-top-container {
      flex-direction: column;
      width: 95%;
      margin-bottom: 5%;
  }

  .footer-category-new {
      width: 100%;
      margin-bottom: 20px;
  }

  .footer-category-3 {
      width: 100%;
      margin-bottom: 10px; /* Reduced margin on the bottom */
  }

  #important-part-container {
      width: 95%;
      font-size: 11px;
  }

  #new-privacy-bottom-last-container {
      flex-direction: column;
      font-size: 12px;
      text-align: center;
  }

  #privacy-container {
      flex-direction: column;
      align-items: center;
  }

  .privacy-item {
      margin: 5px 0;
  }
}



@media (max-width: 480px) {
  #Footer {
      padding-top: 1%;
  }

  #footer-college {
      font-size: 24px; /* Further reduction for mobile */
      text-align: center;
  }

  #footer-left-container {
      width: 100%;
      align-items: center;
      text-align: center;
      margin-bottom: 10px;
  }

  .footer-category-title, .footer-category-title-new, .footer-category-title-3 {
      font-size: 13px;
  }

  .footer-category-title-new {
      margin-left: -14px; /* Reduced left margin for 480px */
  }

  .footer-category-title-last {
      font-size: 14px;
  }

  #footer-top-container {
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-bottom: 10%;
  }

  .footer-category-new {
      width: 85%; /* Smaller width for 480px */
      margin-bottom: 15px;
  }

  .footer-category-3 {
      width: 100%;
      margin-bottom: 15px;
  }

  #important-part-container {
      width: 100%;
      font-size: 10px;
      padding: 15px;

      margin-top: 0px;
  }

  #new-privacy-bottom-last-container {
      flex-direction: column;
      align-items: center;
      font-size: 11px;
  }

  .right-below-bar-footer {
      font-size: 12px; /* Reduced font size for better fit on mobile */
  }

  #privacy-container {
      flex-direction: column;
      align-items: center;
  }

  .privacy-item {
      margin: 4px 0;
  }
}
