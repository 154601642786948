/* Outermost container div */
.outer-container {
  display: flex;
  justify-content: center; /* Center the right-side container */
  width: 100%; /* Full width for responsive scaling */
  margin: 0 auto; /* Center the container */
  height: auto;
  background-color: #cbd9fc;
  border-radius: 15px;
  overflow: hidden; /* Ensures content stays within rounded edges */
  margin-top: 2%;
  margin-bottom: 3%;
  padding: 0px;
  box-sizing: border-box;
}

/* Right side container */
.right-side, .outer-container {
  width: 100%; /* Full width to occupy the entire outer container */
  height: auto; /* Dynamic height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 15px;
  padding-top: 25px;
  padding-bottom: 25px;
  max-width: 800px;
  overflow: hidden; /* Prevent the container from expanding based on text */
  box-sizing: border-box;
}

/* Chat window container */
.chat-window-container {
  height: 100%; /* Dynamic height */
  width: 100%; /* Full width to adapt */
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}

/* Chat window */
.chat-window {
  width: 100%;
  height: 60vh; /* Adjusted height for better fit */
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

/* Individual messages */
.message {
  display: flex;
  padding: 10px;
  margin: 8px 0;
  border-radius: 10px;
  max-width: 70%; /* Adjust to fit smaller screens */
  font-family: 'Poppins', sans-serif;
  font-style: normal;
}

/* User message */
.user-message {
  background-color: #d1e9ff;
  align-self: flex-end;
  text-align: right;
  margin-left: auto;
}

/* Bot message */
.bot-message {
  background-color: #e1f5e6;
  align-self: flex-start;
  text-align: left;
  margin-right: auto;
}

/* Message text */
.message-text {
  line-height: 1.5;
  color: #333;
}

/* Typing indicator */
.typing-indicator {
  font-style: italic;
  color: #757575;
  padding: 10px;
  text-align: center;
}

/* Input container */
.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Full width */
  margin-top: 1%;
}

/* Input field */
.input-field {
  flex-grow: 1;
  padding: 12px 15px;
  border-radius: 15px;
  border: 1px solid #ccc;
  outline: none;
  margin-right: 10px;
  background-color: #ffffff;
  color: #666;
  transition: all 0.3s ease;
  height: 50px;
  box-sizing: border-box;
}

/* Placeholder */
.input-field::placeholder {
  color: #999;
  font-family: 'Raleway', sans-serif;
}

/* Focus state */
.input-field:focus {
  border-color: #00796b;
}

/* Send button */
.send-button {
  background-color: #00796b;
  color: white;
  padding: 12px 20px;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

/* Hover state */
.send-button:hover {
  background-color: #004d40;
}

/* Aza body background */
#aza-body {
  background: #f3f6fd;
  padding-bottom: 2%;
}
