@import url("https://fonts.googleapis.com/css?family=Jost");
@import url("https://fonts.googleapis.com/css?family=Lato");

#timeline {
  width: 70%;
  height: auto;
  max-height: 600px;
  background-size: cover;
  background-position: center;
  background-color: #9199c5;
  border-radius: 10px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  margin: auto;
  padding: 10px;
  margin-bottom: 50px;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

#timeline:hover {
  transform: scale(1.03);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

#page-background {
  height: auto;
  background-color: #dddfe2;
  padding-bottom: 50px;
}

#header-section {
  position: relative;
  width: 100%;
  margin-top: 3%;
  margin-bottom: 1%;
  z-index: 1; /* Ensure header section is on top */
}

#header-image {
  width: 100%;
  height: auto;
  max-height: 400px;
  z-index: 0; /* Ensure the image is below header text */
}

#header-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1; /* Ensure text is above the image */
}

#header-text {
  text-align: center;
  font-size: 68px;
  color: rgb(207, 209, 236);
  z-index: 2; /* Ensure text is above everything */
}

#sub-nav-container {
  margin-top: 10px; /* Add margin to position below header section */
  z-index: 0; /* Ensure it is below the header section */
}

.header-title {
  margin: auto;
}

#sections-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 800px;
  margin-bottom: 100px;
}

.content-section {
  width: 800px;
  padding-top: 18px;
  padding-bottom: 22px;
  padding: 20px;
  border-top: 4px solid #5e6696;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.content-text {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 800px;
  color: rgb(37, 37, 37);
}

.section-title {
  font-size: 30px;
  margin-top: 16px;
  margin-bottom: 8px;
}

.referral-container {
  font-family: "Arial", sans-serif;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.referral-text {
  font-size: 16px;
  line-height: 1.5;
  color: #36932d;
  margin-bottom: 15px;
}

.sub-title {
  font-size: 20px;
  text-decoration: underline;
  margin-top: 16px;
  margin-bottom: 8px;
}

.bullet-list {
  text-align: left;
  font-size: 18px;
  color: rgb(37, 37, 37);
  margin-bottom: 40px;
}

.bullet-item {
  margin-left: 20px;
}

#introduction-section {
  width: 60%;
  margin: 60px auto;
  padding: 40px;
  text-align: center;
  background: #284B63;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  border: 5px solid #fff;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
}

#introduction-section:hover {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

#introduction-text-container {
  font-family: "Lato", sans-serif;
}

#introduction-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

#introduction-title {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 2.5em;
  font-weight: 800;
  position: relative;
}

#title-underline {
  height: 6px;
  width: 120px;
  margin-top: 12px;
  margin-bottom: 20px;
  border-radius: 50px;
  background: #fff;
  transition: width 0.3s ease;
}

#introduction-title:hover + #title-underline {
  width: 180px;
}

#introduction-text {
  color: #fff;
  font-family: "Jost", sans-serif;
  line-height: 1.8;
  margin: 0;
  font-size: 1.2em;
}

#conclusion-section {
  width: 65%;
  margin-bottom: 50px;
  padding: 30px;
  background-color: rgb(103, 72, 72);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  text-align: center;
  font-size: 18px;
  transition: transform 0.3s, box-shadow 0.3s;
}

#conclusion-section:hover {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

#conclusion-text-container {
  font-family: "Lato", sans-serif;
}

#conclusion-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

#conclusion-title {
  color: #2d2d2d;
  margin-bottom: 8px;
  font-size: 2em;
  font-weight: 700;
}

#conclusion-underline {
  height: 4px;
  width: 50px;
  background: #2d2d2d;
  border-radius: 2px;
  transition: width 0.3s ease;
}

#conclusion-title:hover + #conclusion-underline {
  width: 100px;
}

#conclusion-text {
  color: #ffffff;
  font-family: "Jost", sans-serif;
  line-height: 1.6;
}

@media (max-width: 1024px) {
  #timeline {
    width: 80%;
    max-height: 500px;
  }
  #header-text {
    font-size: 50px;
  }
  .section,
  .content-section,
  .content-text {
    width: 600px;
  }
  .section-title {
    font-size: 28px;
  }
  .referral-text {
    font-size: 14px;
  }
  .bullet-list {
    font-size: 16px;
  }
  #introduction-section {
    width: 80%;
    padding: 30px;
  }
  #introduction-title {
    font-size: 2em;
  }
  #introduction-text {
    font-size: 1em;
  }
  #conclusion-section {
    width: 80%;
    padding: 20px;
  }
  #conclusion-title {
    font-size: 1.5em;
  }
  #conclusion-text {
    font-size: 1em;
  }
}

@media (max-width: 768px) {
  #timeline {
    width: 80%;
    max-height: 400px;
  }
  #header-text {
    font-size: 40px;
  }
  .section,
  .content-section,
  .content-text {
    width: 80%;
    padding: 10px;
  }

  .section {
    padding-left: 110px;
  }

  .section-title {
    font-size: 24px;
  }
  .referral-text {
    font-size: 12px;
  }
  .bullet-list {
    font-size: 14px;
  }
  #introduction-section {
    width: 80%;
    padding: 20px;
  }
  #introduction-title {
    font-size: 1.5em;
  }
  #introduction-text {
    font-size: 0.9em;
  }
  #conclusion-section {
    width: 80%;
    padding: 15px;
  }
  #conclusion-title {
    font-size: 1.2em;
  }
  #conclusion-text {
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  #timeline {
    width: 80%;
    max-height: 300px;
  }
  #header-text {
    font-size: 30px;
  }
  .section,
  .content-section,
  .content-text {
    width: 80%;
    padding: 5px;
  }

  .section {
    padding-left: 60px;
  }

  .section-title {
    font-size: 20px;
  }
  .referral-text {
    font-size: 10px;
  }
  .bullet-list {
    font-size: 12px;
  }
  #introduction-section {
    width: 80%;
    padding: 10px;
  }
  #introduction-title {
    font-size: 1.2em;
  }
  #introduction-text {
    font-size: 0.8em;
  }
  #conclusion-section {
    width: 80%;
    padding: 10px;
  }
  #conclusion-title {
    font-size: 1em;
  }
  #conclusion-text {
    font-size: 0.8em;
  }
}
