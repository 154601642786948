@import url("https://fonts.googleapis.com/css?family=Jost");
@import url("https://fonts.googleapis.com/css?family=Lato");

@import url("https://fonts.googleapis.com/css?family=Open Sans");

@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css?family=Raleway");


.home-TOC-page a {
  color: inherit;
  text-decoration: none;
}

.home-TOC-page a:visited {
  color: inherit;
  text-decoration: none;
}

.home-TOC-page a:hover {
  color: inherit;
  text-decoration: none;
}

.home-TOC-page a:focus {
  color: inherit;
  text-decoration: none;
}

.home-TOC-page a:active {
  color: inherit;
  text-decoration: none;
}

#home {
  background: #D9D9D9;
  
}

#home-face {
  position: relative;
  margin-top: 3%;
 
  overflow: hidden; /* Crop the image to the div's height */
  height: 700px; /* Set the desired height of the div */

  
}

#home-pic {
  width: 100%;
  height: auto; /* Maintain the aspect ratio */
  position: absolute; /* Position the image absolutely */
  top: -50px; /* Move the image up by 50px */
  left: 0; /* Align the image to the left of the div */

  animation: fadeIn 1.5s ease-in-out forwards; /* Applying the animation */
}

#home-pic-text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 1%;

  
}

#home-pic-text {
  position: absolute;
  top: 175px;
  left: 75px;
  z-index: 0.99;
  width: 650px;
  text-align: left;

  font-size: 30px;
  line-height: 36px;
  
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-style: normal;

  color: white;
  animation: slideInFromLeft .75s ease-in-out;
}

#home-pic-text h3 {
  color: white;
}

#get-started-link {
  color: white;
  text-decoration: none; /* no underline */
}

#start-button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  color: inherit;
  cursor: pointer;
  outline: none;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 90px;
  width: 350px;
  margin-top: 60px;
  border-radius: 800px;
  border: 2px solid #1e3a8a;
  background: #007bff;

  font-size: 24px;
  font-family: "Jost";
  color: white;
  transition: transform 0.25s ease, background 0.25s ease;
}

#start-button:hover {
  cursor: pointer;
  transform: scale(1.05);
  background: #0055b0;
}

#start-button:active {
  /* Remove the default border change on active state */
  border: 2px solid rgb(94, 102, 150); /* Maintain the same border color */
  background-color: none; /* Optional: Change background to a darker shade on click */
}

#start-button:focus {
  /* Remove the default focus outline */
  outline: none;
}

#introduction-page-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f3f6fd;
}

#home-page-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
  animation: fadeInUp 1.5s ease-in-out;
}

#home-page-title {
  margin: 0;
  font-size: 48px;
  font-family: "Lato", sans-serif;
  color: #000000;
}

#home-page-title-underline {
  height: 4px;
  width: 60px;
  margin-top: 10px;
  border-radius: 2px;
  background: #007bff; /* Blue color */
}

#home-introduction-container {
  width: 65%;
  margin: 50px auto;
  padding: 40px;
  text-align: left;
  font-size: 18px;
  background-color: #ffffff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  animation: fadeInUp 1.5s ease-in-out;
}

#home-introduction-text-container {
  width: 100%;
  font-family: "Lato", sans-serif;
}

#home-introduction-text-main {
  color: #000000;
  font-family: "Jost", sans-serif;
  line-height: 1.6;
  margin-top: 20px;
}

#home-intro-title {
  color: #000000;
  font-size: 28px;
  margin-bottom: 20px;
  position: relative;
}

#home-intro-title::after {
  content: "";
  display: block;
  width: 50px;
  height: 4px;
  background: #007bff; /* Blue color */
  position: absolute;
  bottom: -10px;
  left: 0;
}

#inspirational-quote {
  width: 100%;
  margin-top: 40px;
  padding-left: 20px;
  border-left: 4px solid #007bff; /* Blue color */
  font-style: italic;
  font-size: 24px;
  font-family: "Lato", sans-serif;
  color: #000000;
  line-height: 1.75;
}

#home-table-of-contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;

  
}

#TOC-name {
  font-size: 48px;
  font-family: "Lato";
  color: #2d2d2d;
}

#all-TOC-category-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.home-table-of-contents-category {
  width: 395px;
  margin: 20px;
  padding: 20px;
  text-align: left;
  font-size: 18px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
  animation: fadeInUp 1.5s ease-in-out;
}

.home-table-of-contents-category:hover {
  transform: translateY(-5px);
}

.TOC-category-title {
  padding-bottom: 8px;
  color: #2d2d2d;
  border-bottom: 3px solid #2d2d2d;
  font-family: "Lato";
}

.home-TOC-page {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #007bff;
}

.home-TOC-page:hover {
  cursor: pointer;
  transform: scale(1.02);
  color: #1abc9c;
}

.home-TOC-page-chapter {
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  color: #007bff;
}

.home-TOC-page-chapter:hover {
  cursor: pointer;
  transform: scale(1.02);
  color: #1abc9c;
}

#get-involved-container {
  width: 50%;
  margin: 100px auto;
  padding: 30px;
  text-align: center;
  font-size: 18px;
  font-family: "Jost", sans-serif;
  color: #333;
  background: #f0f2f5;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: box-shadow 0.3s ease-in-out;
}

#get-involved-container:hover {
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}

#get-involved-title {
  color: #2d2d2d;
  border-bottom: 3px solid #2d2d2d;
  font-family: "Lato", sans-serif;
  margin-bottom: 20px;
}

#get-involved-button {
  background: #007bff;
  border: 2px solid #1e3a8a;
  padding: 15px 30px;
  margin-top: 30px;
  border-radius: 25px;
  font-size: 20px;
  font-family: "Lato", sans-serif;
  color: white;
  text-decoration: none;
  display: inline-block;
  transition: background 0.3s ease, transform 0.3s ease;
}

#get-involved-button:hover {
  background: #0055b0;
  transform: scale(1.05);
  cursor: pointer;
}

#get-involved-button:active {
  /* Remove the default border change on active state */
  border: 2px solid rgb(94, 102, 150); /* Maintain the same border color */
  background-color: none; /* Optional: Change background to a darker shade on click */
}

#get-involved-button:focus {
  /* Remove the default focus outline */
  outline: none;
}

#get-involved-button a {
  color: inherit;
  text-decoration: none;
}

#get-involved-button a:visited {
  color: inherit;
  text-decoration: none;
}

#get-involved-button a:hover {
  color: inherit;
  text-decoration: none;
}

#get-involved-button a:focus {
  color: inherit;
  text-decoration: none;
}

#get-involved-button a:active {
  color: inherit;
  text-decoration: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
























#quote-background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: #273562 ;

  padding-top: 60px;
  padding-bottom: 60px;

  border-radius: 2px;
}

#quote-container {
  width: 65%;

  margin-top: 4%;
  margin-bottom: 4%;
}

#quote-icon {
  width: 8%;
  
}

#quote-text {
  font-size: 30px;
  line-height: 1.6;
  margin-bottom: 40px;
  font-style: italic;

  color: white;

  font-family: 'Poppins', sans-serif;
  
  
}


.author-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.author-img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  
 
  
}

.author-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 5px;

  color: white;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}






#home-table-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 80%;
}


#table-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 70%;
}

#smaller-toc-cont {
  width: 40%;
}



#new-TOC-name {
  margin-bottom: 10px;

  font-size: 60px;
  line-height: 65px;
  
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-style: normal;

  color: #273562 ;
}

#new-toc-description {
  width: 100%;
  
  font-size: 20px;

  font-family: 'Raleway';
  font-style: normal;

  color: #273562 ;
}






#new-TOC-categories-container {
  display: flex;
  flex-direction: row;
  
  height: 100%;

  margin-top: 3%;
  margin-bottom: 5%;
  
}


.pricing-card {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  padding: 25px;
  max-width: 335px;
  text-align: left;
  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  margin-left: 20px;
  margin-right: 20px;

  
}

.pricing-card h3 {
  font-size: 28px;
  color: #273562 ;
  margin-bottom: 24px;
  font-weight: bold;


  
  margin-top: 0px;
}

.plan-type {
  font-size: 18px;
  color: #273562 ;
  font-weight: bold;
  margin-bottom: 5px;
}

.billing {
  font-size: 14px;
  color: #888;
  margin-bottom: 20px;
}

.features-list {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.features-list li {
  font-size: 16px;
  color: #333;
  margin-bottom: 12px;
  position: relative;
  padding-left: 30px;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.features-list a {
  
}

.features-list li::before {
  content: '✔';
  color: #21a366;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
}

.get-started-btn {
  background-color: #000;
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  margin-bottom: 10px;
}

.get-started-btn:hover {
  background-color: #333;
  
}














.wholesale-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;

  width: 100%;

  

  
  margin-bottom: 140px;
}

.wholesale-content-box {
  text-align: center;
  color: black;
  padding: 20px;
  max-width: 600px;
}

.wholesale-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.wholesale-description {
  font-size: 20px;
  margin-bottom: 20px;

  
}

.wholesale-button {
  background-color: #4f46e5 ;
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 20px;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.wholesale-button:hover {
  background-color: #3630a0;
  
}












.get-started-btn-s1 {
  background-color: #4f46e5  ;
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  margin-bottom: 10px;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.get-started-btn-s1:hover {
  background-color: #3630a0 ;
}

.get-started-btn-s2:hover {
  background-color: #3630a0 ;
}
.get-started-btn-s3:hover {
  background-color: #3630a0 ;
}
.get-started-btn-s4:hover {
  background-color: #3630a0 ;
}


.get-started-btn-s2 {
  background-color: #4f46e5 ;
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  margin-bottom: 10px;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}


.get-started-btn-s3 {
  background-color: #4f46e5 ;
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  margin-bottom: 10px;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}


.get-started-btn-s4 {
  background-color: #4f46e5 ;
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  margin-bottom: 10px;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}



.pricing-card-new {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  padding: 25px;
  
  text-align: left;
  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  margin-left: 20px;
  margin-right: 20px;

  
  margin-bottom: 20px;


  width: 40%; /* Ensures it fits the full width with margin */
  box-sizing: border-box; /* Includes padding and border in width calculation */
  margin-left: auto;
  margin-right: auto;
}


.pricing-card-new h3 {
  font-size: 28px;
  color: #273562 ;
  margin-bottom: 24px;
  font-weight: bold;

  margin-top: 0px;
}

#testing-thing {
  

  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;

  margin-top: -20px;
  margin-bottom: 60px;
}






.new-bullet-point-thing {
  content: '✔';
  color: #21a366;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;

  opacity: 0;
}














/* Media Query for Tablets and Small Desktops (max-width: 1024px) */
@media only screen and (max-width: 1024px) {
  
  #home-face {
    height: 600px;
    margin-bottom: -3%;
  }

  #home-pic {
    top: -40px;
  }

  #home-pic-text {
    top: 150px;
    left: 50px;
    width: 550px;
    font-size: 26px;
    line-height: 32px;
  }

  #start-button {
    height: 80px;
    width: 300px;
    font-size: 22px;
  }

  #home-page-title {
    font-size: 40px;
  }

  #home-introduction-container {
    width: 70%;
    padding: 30px;
    font-size: 16px;
  }

  .home-table-of-contents-category {
    width: 45%;
  }

  #get-involved-container {
    width: 60%;
    padding: 25px;
    font-size: 16px;
  }

  #quote-text {
    font-size: 24px;
  }

  .pricing-card, .pricing-card-new {
    width: 45%;
  }

  .wholesale-content-box {
    max-width: 500px;
    padding: 15px;
  }

}

/* Media Query for Tablets in Portrait Mode (max-width: 768px) */
@media only screen and (max-width: 768px) {
  
  #home-face {
    height: 500px;
    margin-bottom: -10%;
  }

  #home-pic {
    top: -30px;
  }

  #home-pic-text {
    top: 130px;
    left: 40px;
    width: 100%;
    max-width: 500px;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
  }

  #start-button {
    height: 70px;
    width: 250px;
    font-size: 20px;
  }

  #home-page-title {
    font-size: 36px;
  }

  #home-introduction-container {
    width: 80%;
    padding: 20px;
    font-size: 14px;
  }

  .home-table-of-contents-category {
    width: 80%;
  }

  #get-involved-container {
    width: 75%;
    padding: 20px;
    font-size: 14px;
  }

  #quote-text {
    font-size: 20px;
  }

  .pricing-card, .pricing-card-new {
    width: 80%;
  }

  .wholesale-content-box {
    max-width: 400px;
    padding: 10px;
  }

}

/* Media Query for Mobile Devices (max-width: 480px) */
@media only screen and (max-width: 480px) {
  
  #home-face {
    height: 400px;
    margin-bottom: -30%;
  }

  #home-pic {
    top: -20px;
  }

  #home-pic-text {
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
  }

  #start-button {
    height: 60px;
    width: 200px;
    font-size: 16px;
  }

  #home-page-title {
    font-size: 28px;
  }

  #home-introduction-container {
    width: 95%;
    padding: 15px;
    font-size: 12px;
  }

  .home-table-of-contents-category {
    width: 90%;
  }

  #get-involved-container {
    width: 90%;
    padding: 15px;
    font-size: 12px;
  }

  #quote-text {
    font-size: 16px;
  }

  .pricing-card, .pricing-card-new {
    width: 100%;
  }

  .wholesale-content-box {
    max-width: 300px;
    padding: 8px;
  }

  /* Adjusting button sizes */
  .get-started-btn, .wholesale-button {
    font-size: 14px;
    padding: 10px 15px;
  }

  .TOC-category-title {
    font-size: 18px;
  }

  #quote-icon {
    width: 10%;
  }
}










/* Media Query for Tablets and Small Desktops (max-width: 1024px) */
@media only screen and (max-width: 1024px) {
  
  #introduction-page-main-container {
    padding: 20px;
  }

  #home-page-title {
    font-size: 40px;
  }

  #home-page-title-underline {
    width: 50px;
    height: 3px;
  }

  #home-introduction-container {
    width: 70%;
    padding: 30px;
    font-size: 16px;
  }

  #home-intro-title {
    font-size: 26px;
  }

  #home-introduction-text-main {
    font-size: 16px;
    line-height: 1.5;
  }

  #inspirational-quote {
    font-size: 22px;
    padding-left: 15px;
  }

  /* Ensure home table of contents spans full width */
  #home-table-of-contents {
    width: 100%;
  }

  #home-table-content-container {
    width: 90%;
  }

  #smaller-toc-cont {
    width: 70%;
  }

  #new-TOC-name {
    font-size: 50px;
    line-height: 55px;
  }

  #new-toc-description {
    font-size: 18px;
  }

  #new-TOC-categories-container {
    flex-direction: row;
    justify-content: space-between; /* Ensure two cards per row */
    flex-wrap: wrap;
  }

  /* Adjust pricing-card and pricing-card-new to line up side by side */
  .pricing-card, .pricing-card-new {
    width: 48%; /* Two cards per row */
    margin: 1% auto; /* Adjust margin for spacing */
  }

  /* Ensure quote background is full width */
  #quote-background {
    width: calc(100% + 40px); /* Add extra width to stretch beyond screen edges */
    margin-left: -20px; /* Offset the extra width */
    margin-right: -20px;
  }

  #quote-text {
    font-size: 24px;
  }

  .wholesale-content-box {
    max-width: 500px;
    padding: 15px;
  }

  .wholesale-button {
    font-size: 18px;
    padding: 10px 20px;
  }

}

/* Media Query for Tablets in Portrait Mode (max-width: 768px) */
@media only screen and (max-width: 768px) {
  
  #introduction-page-main-container {
    padding: 15px;
  }

  #home-page-title {
    font-size: 36px;
  }

  #home-page-title-underline {
    width: 45px;
    height: 3px;
  }

  #home-introduction-container {
    width: 80%;
    padding: 25px;
    font-size: 14px;
  }

  #home-intro-title {
    font-size: 24px;
  }

  #home-introduction-text-main {
    font-size: 14px;
    line-height: 1.5;
  }

  #inspirational-quote {
    font-size: 20px;
    padding-left: 10px;
  }

  /* Ensure home table of contents spans full width */
  #home-table-of-contents {
    width: 100%;
  }

  #home-table-content-container {
    width: 100%;
  }

  #smaller-toc-cont {
    width: 80%;
  }

  #new-TOC-name {
    font-size: 40px;
    line-height: 45px;
  }

  #new-toc-description {
    font-size: 16px;
  }

  #new-TOC-categories-container {
    flex-direction: column;
    align-items: center;
  }

  /* Ensure pricing cards are a lot wider */
  .pricing-card, .pricing-card-new {
    width: 90%; /* Make cards significantly wider */
    margin: 10px auto; /* Center the cards */
  }

 
  /* Ensure quote background is full width */
  #quote-background {
    width: calc(100% + 30px); /* Add extra width to stretch beyond screen edges */
    margin-left: -15px; /* Offset the extra width */
    margin-right: -15px;
  }

  #quote-text {
    font-size: 20px;
  }

  .wholesale-content-box {
    max-width: 400px;
    padding: 10px;
  }

  .wholesale-button {
    font-size: 16px;
    padding: 8px 16px;
  }

  

}

/* Media Query for Mobile Devices (max-width: 480px) */
@media only screen and (max-width: 480px) {
  
  /* Remove margin on right to fit full width */
  body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }

  #introduction-page-main-container {
    padding: 10px;
  }

  #home-face {
    margin-top: 0px;
  }

  #home-pic-text{
    margin-top: -14px;
  }

  #home-page-title {
    font-size: 28px;
  }

  #home-page-title-underline {
    width: 40px;
    height: 2px;
  }

  #home-introduction-container {
    width: 95%;
    padding: 15px;
    font-size: 12px;
  }

  #home-intro-title {
    font-size: 22px;
  }

  #home-introduction-text-main {
    font-size: 12px;
    line-height: 1.4;
  }

  #inspirational-quote {
    font-size: 18px;
    padding-left: 8px;
  }

  /* Ensure home table of contents spans full width */
  #home-table-of-contents {
    width: 100%;
  }

  #home-table-content-container {
    width: 100%;
  }

  #smaller-toc-cont {
    width: 90%;
  }

  #new-TOC-name {
    font-size: 32px;
    line-height: 35px;
  }

  #new-toc-description {
    font-size: 14px;
  }

  #new-TOC-categories-container {
    flex-direction: column;
    align-items: center;
  }

  /* Ensure pricing cards have the same width as .pricing-card-new */
  .pricing-card, .pricing-card-new {
    width: 87.5%; /* Adjust width to fit mobile screen */
    margin: 10px auto; /* Center the cards */
  }

  /* Ensure quote background is full width */
  #quote-background {
    width: calc(100% + 20px); /* Add extra width to stretch beyond screen edges */
    margin-left: -10px; /* Offset the extra width */
    margin-right: -10px;
  }

  #quote-text {
    font-size: 16px;
  }

  .wholesale-content-box {
    max-width: 300px;
    padding: 8px;
  }

  .wholesale-button {
    font-size: 14px;
    padding: 8px 14px;
  }

  .pricing-card {
    /* Adjust width, padding, margins, etc. */
    padding: 20px;

  }

  .plan-type {
    font-size: 14px; /* Example adjustment */
  }

  .features-list li {
    font-size: 14px; /* Example adjustment */
    margin-bottom: 8px; /* Adjust spacing as needed */
  }

  .link {
    font-size: 14px; /* Example adjustment */
  }

  .pricing-card h3 {
    font-size: 20px; /* Example adjustment */
  }

  .pricing-card-new h3 {
    font-size: 20px; /* Example adjustment */
  }





  .get-started-btn-s1 {
    font-size: 16px; /* Example adjustment */
  }
  
  
  
  
  .get-started-btn-s2 {
    font-size: 16px; /* Example adjustment */
  }
  
  
  .get-started-btn-s3 {
    font-size: 16px; /* Example adjustment */
  }
  
  
  .get-started-btn-s4 {
    font-size: 16px; /* Example adjustment */
  }
}


















/* Media Query for Mobile Devices (max-width: 390px) */
@media only screen and (max-width: 390px) {

  #home-face {
    height: 350px;
    margin-bottom: -35%;
  }

  #home-pic {
    top: -10px;
    width: 100%;
    height: auto;
    position: absolute;
  }

  #home-pic-text-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 1%;
  }

  #home-pic-text {
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    width: 85%;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    position: absolute;
  }
}
