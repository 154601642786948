.comment-list {
    max-width: 50%;
    list-style-type: none;
    padding: 0;
    margin: 30px auto;
  }
  
  .comment {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease;
  }
  
  .comment:hover {
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.12);
    transform: translateY(-3px);
  }
  
  .comment__wrapper {
    display: flex;
    flex-direction: column;
  }
  
  .comment__body {
    flex-grow: 1;
  }
  
  .c-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #ecf0f1;
  }
  
  .comment__author {
    font-size: 1rem;
    color: #2c3e50;
    font-weight: 600;
  }
  
  .comment__date {
    font-size: 0.85rem;
    color: #7f8c8d;
  }
  
  .comment__content {
    font-size: 1rem;
    line-height: 1.6;
    color: #34495e;
    margin-bottom: 1rem;
    text-align: left;
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
  }
  
  .comment__actions {
    display: flex;
    justify-content: flex-end;
  }
  
  .reply-btn {
    background-color: transparent;
    color: #3498db;
    border: 2px solid #3498db;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .reply-btn:hover {
    background-color: #3498db;
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 3px 10px rgba(52, 152, 219, 0.3);
  }
  
  .replies {
    list-style-type: none;
    padding-left: 2rem;
    margin-top: 1.5rem;
    border-left: 2px solid #3498db;
  }
  
  .replies .comment {
    background-color: #f7f9fc;
  }
  
  @media (max-width: 768px) {
    .comment-respond,
    .comment-list {
      max-width: 90%;
    }
  
    .comment {
      padding: 1rem;
    }
  
    .replies {
      padding-left: 1rem;
    }
  }