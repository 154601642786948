.gpa-calculator {
  background-color: #273562;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid #e6e8eb;
  width: 80%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 50px;
  font-family: 'Roboto', sans-serif;
}

.gpa-calculator-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 700;
  color: white ;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.gpa-calculator-intro {
  text-align: center;
  margin-bottom: 20px;
  font-size: 16px;
  color: white;

  font-family: 'Poppins', sans-serif;
 
  font-style: normal;
}

.course-list {
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  width: 100%;
}

.course-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 20px;
}

.input-group {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  width: 15%; /* Adjust as needed */

  margin-left: 24px;
}

.input-label {
  font-size: 16px;
  margin-bottom: 5px;
  color: white;
  font-weight: 500;

  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  font-style: normal;
}

.input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccd0d5;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
  outline: none;
  background-color: #fff;
  
  transition: all 0.3s ease;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.input:focus {
  border-color: #3b82f6;
}

.course-weighted {
  margin-top: 5px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.remove-course-button {
  background-color: #e75d5d ; /* Change the button color to the hover color */
  color: white;
  border: none;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center; /* Center the content (X) vertically */
  justify-content: center; /* Center the content (X) horizontally */
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.remove-course-button:hover {
  background-color: #e63939; /* Darker red on hover */
}

.add-course-button,
.calculate-gpa-button,
.toggle-grade-table-button {
  background-color: #4f46e5;
  color: white;
  padding: 12px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 90%;
  text-align: center;
  font-size: 16px;
  
  transition: background-color 0.3s ease;

  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-style: normal;
}

.add-course-button:hover,
.calculate-gpa-button:hover,
.toggle-grade-table-button:hover {
  background-color: #3630a0;
}

.error-message {
  color: #e74c3c;
  margin: 10px 0;
  font-size: 16px;
  text-align: center;
}

.grade-table {
  width: 100%;
  margin-top: 20px;
  text-align: center;
}

.grade-table-title {
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
}

.grade-table-content {
  width: 100%;
  border-collapse: collapse;
}

.grade-table-row {
  border: 1px solid #ccc;
}

.grade-table-header {
  padding: 8px;
  background-color: #f2f2f2;
  color: #333;
  font-size: 16px;
}

.grade-table-cell {
  padding: 8px;
  text-align: center;
  font-size: 16px;
  color: #333;
}

.gpa-result {
  text-align: center;
  margin-top: 20px;
  background-color: white ;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #273562;
  font-size: 16px;

  font-family: 'Poppins', sans-serif;
 
  font-style: normal;
}

.gpa-result-gpa,
.gpa-result-credits {
  margin: 5px 0;
  font-size: 20px;
  font-weight: bold;
}











/* Media query for 1024px screen size */
@media (max-width: 1024px) {
  .gpa-calculator {
    width: 90%;
    padding: 18px;
    margin-top: 80px;
    margin-bottom: 40px;
  }

  .gpa-calculator-title {
    font-size: 22px;
    margin-bottom: 18px;
  }

  .gpa-calculator-intro {
    font-size: 15px;
    margin-bottom: 18px;
  }

  .course-list {
    margin-left: 50px;
  }

  .course-item {
    margin-bottom: 18px;
  }

  .input-group {
    width: 18%;
    margin-left: 16px;
  }

  .input-label {
    font-size: 15px;
  }

  .input {
    font-size: 13px;
  }

  .add-course-button,
  .calculate-gpa-button,
  .toggle-grade-table-button {
    padding: 10px;
    font-size: 15px;
  }

  .gpa-result {
    padding: 18px;
    margin-top: 18px;
  }

  .gpa-result-gpa,
  .gpa-result-credits {
    font-size: 18px;
  }
}

/* Media query for 768px screen size */
@media (max-width: 768px) {
  .gpa-calculator {
    width: 85%;
    padding: 16px;
    margin-top: 70px;
    margin-bottom: 35px;
  }

  .gpa-calculator-title {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .gpa-calculator-intro {
    font-size: 14px;
    margin-bottom: 16px;
  }

  .course-list {
    margin-left: 20px;
  }

  .course-item {
    margin-bottom: 16px;
  }

  .input-group {
    width: 20%;
    margin-left: 12px;
  }

  .input-label {
    font-size: 14px;
  }

  .input {
    font-size: 12px;
  }

  .add-course-button,
  .calculate-gpa-button,
  .toggle-grade-table-button {
    padding: 9px;
    font-size: 14px;
  }

  .gpa-result {
    padding: 16px;
    margin-top: 16px;
  }

  .gpa-result-gpa,
  .gpa-result-credits {
    font-size: 17px;
  }
}

/* Media query for 480px screen size */
@media (max-width: 480px) {
  .gpa-calculator {
    width: 85%; /* Adjusted width to provide more room on the sides */
    padding: 14px;
    margin-top: 60px;
    margin-bottom: 30px;
  }

  .gpa-calculator-title {
    font-size: 18px;
    margin-bottom: 14px;
  }

  .gpa-calculator-intro {
    font-size: 13px;
    margin-bottom: 14px;
  }

  .course-list {
    margin-left: 0;
  }

  .course-item {
    margin-top: -24px;
    margin-bottom: 14px;
    flex-direction: column; /* Stack items vertically on small screens */
    align-items: flex-start;
  }

  .input-group {
    width: 100%;
    margin-left: 0;
  }

  .input-label {
    font-size: 13px;
    margin-bottom: 0px;
  }

  .input {
    font-size: 11px;
    padding: 7px;
    width: 90%; /* Reduced the width to make input boxes less wide */
  }

  .add-course-button,
  .calculate-gpa-button,
  .toggle-grade-table-button {
    padding: 8px;
    font-size: 13px;
  }

  .gpa-result {
    padding: 14px;
    margin-top: 14px;
  }

  .gpa-result-gpa,
  .gpa-result-credits {
    font-size: 16px;
  }

  .hide-on-480 {
    display: none;
  }
}
