.financial-tracker {
  max-width: 1000px; /* Maintained the wider max-width for side-by-side forms */
  margin: 30px auto;
  padding: 40px;
  background-color: #273562 ;
  border-radius: 12px;
  border: 1px solid #e6e8eb;
  font-family: 'Roboto', sans-serif;

  margin-top: 2%;
}

.form-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px; /* Space below the side-by-side forms */
}

.financial-aid,
.expense-tracker {
  flex: 1;
}

.financial-aid {
  margin-right: 50px; /* Adjusted space between the two forms */
  margin-left: -10px;
}

.financial-aid__title,
.expense-tracker__title,
.financial-summary__title {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: white ;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.financial-aid-form,
.expense-form {
  margin-bottom: 20px;
}

.financial-aid-form__group,
.expense-form__group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.financial-aid-form__label,
.expense-form__label {
  flex: 1;
  font-size: 1rem;
  color: white;
  margin-right: 10px; /* Adjusted to ensure forms stay within the container */

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.financial-aid-form__input,
.expense-form__input,
.expense-form__select {
  flex: 2;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #dadada;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;

  font-family: 'Poppins', sans-serif;
 
  font-style: normal;
  
}

.financial-aid-form__input:focus,
.expense-form__input:focus,
.expense-form__select:focus {
  border-color: #007bff;
}

.expense-form__input--other {
  flex: 2;
  margin-right: 10px;
}

.expense-form__select {
  flex: 1.5;
  margin-right: 10px;
}

.financial-aid__total,
.expense-form__total {
  text-align: right;
  font-size: 1.2rem;
  margin-top: 10px;
  color: white;
  font-weight: bold;

  font-family: 'Raleway', sans-serif;
  
  font-style: normal;
}

.financial-aid__total {
  margin-right: 10px; /* Adjusted to ensure alignment with Total Expenses */
}

.financial-summary {
  margin-top: 30px;
  padding: 20px;
  background-color: #f7f9fc;
  border-radius: 12px;
  border: 1px solid #e6e8eb;
 
}

.financial-summary__title {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #4a4a4a;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
}

.financial-summary__item {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 8px;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.financial-summary__item:last-child {
  font-weight: bold;
  color: #4f46e5 ;
}

.expense-form__add-other-button {
  display: inline-block;
  margin-top: 15px;
  padding: 8px 12px;
  background-color: #4f46e5;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;

  font-family: 'Poppins', sans-serif;
 
  font-style: normal;
}

.expense-form__add-other-button:hover {
  background-color: #3630a0;
}

/* Button group */
.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.save-button,
.reset-button {
  padding: 10px 30px; /* Increased padding for wider buttons */
  font-size: 1rem;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  min-width: 150px; /* Ensure a minimum width for consistency */

  
}

.save-button {
  background-color: #007bff;

  
}

.save-button:hover {
  background-color: #0056b3;
}

.reset-button {
  background-color: #dc3545;
  position: relative;
  overflow: hidden;
}

.reset-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: rgba(23, 149, 228, 0.3);
  transition: width 0.5s linear;
}

.reset-button.resetting::before {
  width: 100%;
}

.reset-button:hover {
  background-color: #c82333;
}



#financial-tracker-background {
  background: #f3f6fd;
  padding-bottom: 2%;
}












/* Media query for 1024px screen size */
@media (max-width: 1024px) {
  .financial-tracker {
    max-width: 900px;
    padding: 35px;
    margin-top: 3%;
  }

  .form-container {
    flex-direction: column;
    margin-bottom: 25px;
  }

  .financial-aid {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .financial-aid__title,
  .expense-tracker__title,
  .financial-summary__title {
    font-size: 1.4rem;
  }

  .financial-aid-form__label,
  .expense-form__label {
    font-size: 0.95rem;
  }

  .financial-aid-form__input,
  .expense-form__input,
  .expense-form__select {
    padding: 7px;
    font-size: 13px;
  }

  .financial-aid__total,
  .expense-form__total {
    font-size: 1.1rem;
  }

  .financial-summary {
    padding: 18px;
  }

  .financial-summary__item {
    font-size: 1rem;
  }

  .expense-form__add-other-button {
    padding: 7px 10px;
    font-size: 0.95rem;
  }

  .save-button,
  .reset-button {
    padding: 8px 25px;
    font-size: 0.95rem;
    min-width: 130px;
  }

  #financial-tracker-background {
    padding-bottom: 3%;
  }
}

/* Media query for 768px screen size */
@media (max-width: 768px) {
  .financial-tracker {
    max-width: 600px;
    padding: 25px;
    margin-top: 4%;
  }

  .form-container {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .financial-aid {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .financial-aid__title,
  .expense-tracker__title,
  .financial-summary__title {
    font-size: 1.3rem;
  }

  .financial-aid-form__label,
  .expense-form__label {
    font-size: 0.9rem;
  }

  .financial-aid-form__input,
  .expense-form__input,
  .expense-form__select {
    padding: 6px;
    font-size: 12px;
  }

  .financial-aid__total,
  .expense-form__total {
    font-size: 1rem;
  }

  .financial-summary {
    padding: 15px;
  }

  .financial-summary__item {
    font-size: 0.95rem;
  }

  .expense-form__add-other-button {
    padding: 6px 8px;
    font-size: 0.9rem;
  }

  .save-button,
  .reset-button {
    padding: 7px 20px;
    font-size: 0.9rem;
    min-width: 120px;
  }

  #financial-tracker-background {
    padding-bottom: 4%;
  }
}

/* Media query for 480px screen size */
@media (max-width: 480px) {
  .financial-tracker {
    max-width: 85%; /* Reduced width to allow more space on the sides */
    padding: 20px;
    margin-top: 5%;
  }

  .form-container {
    flex-direction: column;
    margin-bottom: 15px;
  }

  .financial-aid {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .financial-aid__title,
  .expense-tracker__title,
  .financial-summary__title {
    font-size: 1.2rem;
  }

  .financial-aid-form__label,
  .expense-form__label {
    font-size: 0.85rem;
  }

  .financial-aid-form__input,
  .expense-form__input,
  .expense-form__select {
    padding: 5px;
    font-size: 11px;
    width: 100%; /* Ensure input boxes don't break out */
  }

  .financial-aid__total,
  .expense-form__total {
    font-size: 0.95rem;
  }

  .financial-summary {
    padding: 10px;
  }

  .financial-summary__item {
    font-size: 0.9rem;
  }

  .expense-form__add-other-button {
    padding: 5px 7px;
    font-size: 0.85rem;
  }

  .save-button,
  .reset-button {
    padding: 6px 15px;
    font-size: 0.85rem;
    min-width: 100px;
  }

  #financial-tracker-background {
    padding-bottom: 5%;
  }
}
