@import url("https://fonts.googleapis.com/css?family=Jost");
@import url("https://fonts.googleapis.com/css?family=Lato");

@import url("https://fonts.googleapis.com/css?family=Open Sans");

@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css?family=Raleway");

/* Timeline Section */
#timeline {
  width: 70%; /* Adjust width as needed */
  height: auto; /* Maintain aspect ratio */
  max-height: 600px; /* Set a maximum height */
  background-size: cover; /* Scale the image to cover the container */
  background-position: center; /* Center the image */
  background-color: #9199c5;
  border-radius: 10px; /* Optional: Add rounded corners */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15); /* Enhanced shadow for better depth */
  margin: auto; /* Center the timeline horizontally and add margin for spacing */
  padding: 10px; /* Optional: Add padding for inner content */
  position: relative; /* Positioning context for inner elements */
  overflow: hidden; /* Prevents overflow of inner content */
  transition: box-shadow 0.3s ease; /* Smooth transition for hover effects */
}

#timeline:hover {
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2); /* Slightly enhanced shadow on hover */
}

p {
  line-height: 1.5;
}

#page-background {
  height: auto;
  background: #f3f6fd;
}

#newest-background {
  height: auto;
  background: #f3f6fd;
}

#gpa-page-background {
  background: #f3f6fd;
  padding-bottom: 2%;
}

#ecr-background {
  background: #f3f6fd;
}

#Ch-face-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#Ch-face-text {
  text-align: center;
  font-size: 68px;
  color: #ffffff;
}

.chapter-number {
  font-size: 32px;
  margin-bottom: -60px;
}

.Ch-header-text {
  margin-bottom: -45px;
}

.Ch-header-text-two {
  font-size: 46px;
}

.smaller-sub-nav-item {
  margin-bottom: 0.35%;
}

.smaller-sub-nav-item:hover {
  cursor: pointer;
  transform: scale(1.025);
  text-decoration: underline;
}

.smaller-sub-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 14%;
  font-family: "Lato";
  font-size: 20px;
  color: rgb(232, 237, 248);
}

.main-sub-slash {
  margin-top: 0.25%;
  margin-left: 14px;
  margin-right: 14px;
  font-size: 30px;
}

.smaller-sub-slash {
  margin-bottom: 0.35%;
  margin-left: 14px;
  margin-right: 14px;
  font-size: 22px;
}

#whole-section-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: -100px;
}

.section-and-number-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: -80px;
  padding: auto;
}

/* Updated Section Styling */
.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0; /* Added margin for spacing between sections */
  background-color: #f7f9fc;
  border-radius: 12px;
  padding: 20px;
  margin: 30px auto;
  border: 1px solid #e6e8eb;
  font-family: 'Roboto', sans-serif;
}

.section-number-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 125px;
  height: 50px;
  margin-right: 36px;
  border-radius: 20px;
  background: rgb(91, 102, 168);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section-number {
  margin: 10px 0;
  font-size: 28px;
  font-family: "Lato";
  color: white;
}

/* Updated Main Section Styling */
.main-section {
  width: 42.5%; /* Increased width to fill the space */
  padding: 20px 40px; /* Added padding for better spacing */
  text-align: center;
  background-color: #ffffff;
  border-radius: 12px;
  margin: 30px auto;
  border: 2px solid #eef3fc;
  
  font-family: 'Roboto', sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: box-shadow 0.3s ease; /* Smooth transition for hover effects */
}

.main-section:hover {
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15); /* Subtle increase in shadow on hover */
}

.main-text {
  text-align: left;
  width: 100%;
  color: rgb(37, 37, 37);
  font-size: 18px;
  line-height: 1.7; /* Consistent line height */
}

.chapter-title {
  color: #2c366c;
  font-size: 30px;
  margin-top: 16px;
  margin-bottom: 30px;

  padding: 8px;

  
  background-color: #cbd9fc; /* Very light blue background */
  border-radius: 10px;
  
  text-align: center;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
  
  
}

.chapter-title-box {
  background-color: #dbe2f0; /* Very light blue background */

  
}


.chapter-sub-title {
  font-size: 22px;
  text-decoration: underline;
  margin-top: 16px;
  margin-bottom: 8px;
}

.chapter-description {
  font-size: 18px;
}

.bullet-point-list {
  text-align: left;
  font-size: 18px;
  color: #273562 ;
  margin-bottom: 40px;
  line-height: 1.7; /* Increased line height */

  
}

.bullet-point {
  margin-left: 20px;
  margin-bottom: 10px; /* Reduced margin from 15px to 10px */
  line-height: 1.5; /* Keeps the line height slightly increased */

  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-style: normal;

  margin-left: 0px;
}

#button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 80px;
  padding-bottom: 40px;
}

#left-button,
#right-button {
  background-color: rgb(94, 102, 150);
  color: white;
  border: none;
  padding: 15px 30px; /* Increased padding */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px; /* Increased font size */
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

#left-button {
  margin-left: 100px;
}

#right-button {
  margin-right: 100px;
}

#left-button:hover,
#right-button:hover {
  background-color: rgb(75, 82, 123);
}

#left-button:active,
#right-button:active {
  background-color: rgb(56, 62, 94);
}

/* Redesigned Base Page Introduction Container */
#base-page-introduction-container {
  width: 60%;
  margin: 60px auto;
  padding: 50px 40px;
  text-align: center;
  background: #273562 ; /* Solid color that mimics a gradient feel */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  position: relative;
  overflow: hidden;

  
  /* border: 4px solid #fff;*/
}

#base-page-introduction-text-container {
  font-family: "Lato", sans-serif;
}

#base-page-introduction-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

#base-page-intro-title {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 2.5em;
  font-weight: 800;
  position: relative;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;

}

#base-page-title-underline {
  height: 4px;
  width: 100px;
  margin-top: 12px;
  margin-bottom: 20px;
  border-radius: 2px;
  background: #fff;
  transition: width 0.3s ease;
}

#base-page-introduction-text-main {
  color: #f3f6fd;
  
  line-height: 1.8;
  margin: 0;
  font-size: 1.2em;

  font-family: 'Raleway', sans-serif;
  
  font-style: normal;

}

#ch-base-page-container {
  width: 62%;
  margin: 50px auto;
  padding: 30px;
  background-color: #3C6E71;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  text-align: center;
  font-size: 18px;
  transition: transform 0.3s, box-shadow 0.3s;
}

#ch-base-page-container:hover {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

#ch-base-page-text-container {
  font-family: "Lato", sans-serif;
}

#ch-base-page-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

#ch-base-page-title {
  color: #ffffff;
  margin-bottom: 8px;
  font-size: 2em;
  font-weight: 700;
}

#ch-base-page-underline {
  height: 4px;
  width: 50px;
  background: #ffffff;
  border-radius: 2px;
  transition: width 0.3s ease;
}

#ch-base-page-title:hover + #ch-base-page-underline {
  width: 100px;
}

#ch-base-page-text-main {
  color: #000000;
  font-family: "Jost", sans-serif;
  line-height: 1.6;
}

#base-page-conclusion-container {
  width: 65%;
  margin: 50px auto;
  padding: 30px;
  background-color: rgb(103, 72, 72);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  text-align: center;
  font-size: 18px;
  transition: transform 0.3s, box-shadow 0.3s;
}

#base-page-conclusion-container:hover {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

#base-page-conclusion-text-container {
  font-family: "Lato", sans-serif;
}

#base-page-conclusion-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

#base-page-conclusion-title {
  color: #ffffff;
  margin-bottom: 8px;
  font-size: 2em;
  font-weight: 700;
}

#base-page-conclusion-underline {
  height: 4px;
  width: 50px;
  background: #ffffff;
  border-radius: 2px;
  transition: width 0.3s ease;
}

#base-page-conclusion-title:hover + #base-page-conclusion-underline {
  width: 100px;
}

#base-page-conclusion-text-main {
  color: #ffffff;
  font-family: "Jost", sans-serif;
  line-height: 1.6;
}







.main-section img {
  width: 100%; /* Make the image take up the full width of the container */
  height: auto; /* Maintain the image's aspect ratio */
  max-width: 100%; /* Ensure the image does not exceed the container's width */
  display: block; /* Remove any extra spacing */
  margin: 0 auto; /* Center the image horizontally */
  object-fit: cover; /* Optionally, ensure the image covers the container without distortion */
  border-radius: 8px; /* Optional: add rounded corners to match the container's style */
}












#sub-scholarship-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  
  color: rgb(255, 255, 255);
  text-align: center;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  animation: fadeIn .75s ease;

  
  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

#sub-scholarship-text {
  text-align: center;
  
  color: #ffffff;
}

#sub-scholarship-chapter {
  font-size: 32px;
  
}

#sub-scholarship-ch-header-text {
  font-size: 64px;

  margin-bottom: -40px;
  margin-top: -20px;
}

#sub-scholarship-ch-text-two {
  font-size: 40px;
}



























/* Responsive Design */
@media (max-width: 1024px) {
  #timeline {
    width: 80%;
    max-height: 500px;
  }

  #Ch-face-text {
    font-size: 48px;
  }

  .chapter-number {
    font-size: 28px;
  }

  .Ch-header-text-two {
    font-size: 40px;
  }

  .smaller-sub-nav {
    font-size: 18px;
  }

  .main-sub-slash {
    font-size: 24px;
  }

  .smaller-sub-slash {
    font-size: 20px;
  }

  .section-number-container {
    width: 100px;
    height: 40px;
  }

  .section-number {
    font-size: 24px;
  }

  .main-section {
    width: 80%;
  }

  .main-text {
    font-size: 16px;
  }

  .chapter-title {
    font-size: 28px;
  }

  .chapter-sub-title {
    font-size: 18px;
  }

  .chapter-description,
  .bullet-point-list,
  .bullet-point {
    font-size: 16px;
  }

  #button-container {
    margin-top: 60px;
    padding-bottom: 30px;
  }

  #left-button,
  #right-button {
    padding: 12px 24px;
    font-size: 16px;
  }

  #base-page-introduction-container {
    width: 80%;
  }

  #base-page-intro-title {
    font-size: 2em;
  }

  #base-page-introduction-text-main {
    font-size: 1em;
  }

  #ch-base-page-container,
  #base-page-conclusion-container {
    width: 80%;
  }

  #ch-base-page-title,
  #base-page-conclusion-title {
    font-size: 1.5em;
  }

  #ch-base-page-text-main,
  #base-page-conclusion-text-main {
    font-size: 1em;
  }
}

@media (max-width: 768px) {
  #timeline {
    width: 90%;
    max-height: 400px;
  }

  #Ch-face-text {
    font-size: 40px;
  }

  .chapter-number {
    font-size: 24px;
    margin-bottom: 5px; /* Add margin */
  }

  .Ch-header-text {
    font-size: 28px;
    margin-bottom: 5px; /* Add margin */
  }

  .Ch-header-text-two {
    font-size: 28px;
    margin-bottom: 5px; /* Add margin */
  }

  .smaller-sub-nav {
    font-size: 16px;
  }

  .main-sub-slash {
    font-size: 20px;
  }

  .smaller-sub-slash {
    font-size: 18px;
  }

  .section-number-container {
    width: 80px;
    height: 32px;
    margin-left: 110px;
    padding-bottom: 8px;
  }

  .section-number {
    font-size: 20px;
  }

  .main-section {
    width: 80%;
  }

  .main-text {
    font-size: 14px;
  }

  .chapter-title {
    font-size: 24px;
  }

  .chapter-sub-title {
    font-size: 16px;
  }

  .chapter-description,
  .bullet-point-list,
  .bullet-point {
    font-size: 14px;
  }

  #button-container {
    margin-top: 50px;
    padding-bottom: 25px;
  }

  #left-button,
  #right-button {
    padding: 10px 20px;
    font-size: 14px;
  }

  #base-page-introduction-container {
    width: 90%;
    padding: 20px;
  }

  #base-page-intro-title {
    font-size: 1.5em;
    margin-bottom: -10px; /* Reduced margin */
  }

  #base-page-introduction-text-main {
    font-size: 0.9em;
  }

  #ch-base-page-container,
  #base-page-conclusion-container {
    width: 90%;
    padding: 20px;
  }

  #ch-base-page-title,
  #base-page-conclusion-title {
    font-size: 1.2em;
  }

  #ch-base-page-text-main,
  #base-page-conclusion-text-main {
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  #timeline {
    width: 80%;
    max-height: 300px;
  }

  #Ch-face-text {
    font-size: 20px;
    line-height: 1.2; /* Adjust line height */
  }

  .chapter-number {
    font-size: 18px;
    margin-bottom: 10px; /* Add margin */
  }

  .Ch-header-text {
    font-size: 24px;
    margin-bottom: 10px; /* Add margin */
  }

  .Ch-header-text-two {
    font-size: 24px;
    margin-bottom: 10px; /* Add margin */
  }

  .smaller-sub-nav {
    font-size: 14px;
    margin-bottom: 5px; /* Add margin */
  }

  .main-sub-slash {
    font-size: 16px;
    margin-bottom: 5px; /* Add margin */
  }

  .smaller-sub-slash {
    font-size: 14px;
    margin-bottom: 5px; /* Add margin */
  }

  .section-number-container {
    width: 60px;
    height: 24px;
    margin-left: 60px;
    padding-bottom: 8px;
  }

  .section-number {
    font-size: 16px;
  }

  .main-section {
    width: 80%;
    padding: 10px;
  }

  .main-text {
    font-size: 14px; /* Increased font size for better readability */
    line-height: 1.5; /* Adjust line height */
  }

  .chapter-title {
    font-size: 1.4em; /* Increased slightly for emphasis */
    margin-top: 10px; /* Add margin */
    margin-bottom: 8px; /* Adjusted margin */
  }

  .chapter-sub-title {
    font-size: .97em; /* Increased slightly for emphasis */
    margin-top: 10px; /* Add margin */
    margin-bottom: 8px; /* Adjusted margin */
  }

  .chapter-description,
  .bullet-point-list,
  .bullet-point {
    font-size: .97em; /* Increased for better readability */
    line-height: 1.5; /* Adjust line height */
  }

  #button-container {
    margin-top: 40px;
    padding-bottom: 20px;
  }

  #left-button,
  #right-button {
    padding: 10px 20px; /* Increased padding for easier touch targets */
    font-size: 14px; /* Increased font size for better readability */
  }

  #base-page-introduction-container {
    width: 80%;
    padding: 10px;
  }

  #base-page-intro-title {
    font-size: 1.4em; /* Slightly increased for emphasis */
    margin-top: 10px; /* Add margin */
    margin-bottom: -12px; /* Reduced margin */
  }

  #base-page-introduction-text-main {
    font-size: .9em; /* Increased for better readability */
    line-height: 1.5; /* Adjust line height */
  }

  #ch-base-page-container,
  #base-page-conclusion-container {
    width: 80%;
    padding: 10px;
  }

  #ch-base-page-title,
  #base-page-conclusion-title {
    font-size: 1.2em; /* Slightly increased for emphasis */
    margin-top: 10px; /* Add margin */
    margin-bottom: 8px; /* Adjusted margin */
  }

  #ch-base-page-text-main,
  #base-page-conclusion-text-main {
    font-size: 1em; /* Increased for better readability */
    line-height: 1.5; /* Adjust line height */
  }
}






















/* Media Query for 1024px */
@media screen and (max-width: 1024px) {
  #prep-TOC-face-pic {
    top: -200px;
  }

  #afford-TOC-face-pic {
    top: -100px;
    margin-top: 100px;
  }

  #life-TOC-face-pic {
    top: -150px;
    margin-top: 150px;
  }

  #prog-TOC-face-pic {
    top: -250px;
    margin-top: 250px;
  }

  #tool-TOC-face-pic {
    top: -150px;
    margin-top: 150px;
  }

  #about-TOC-face-pic {
    top: -100px;
    margin-top: 100px;
  }
}

/* Media Query for 768px */
@media screen and (max-width: 768px) {
  #prep-TOC-face-pic {
    top: -150px;
    margin-top: 150px;
  }

  #afford-TOC-face-pic {
    top: -150px;
    margin-top: 150px;
  }

  #life-TOC-face-pic {
    top: -100px;
    margin-top: 100px;
  }

  #prog-TOC-face-pic {
    top: -200px;
    margin-top: 200px;
  }

  #tool-TOC-face-pic {
    top: -100px;
    margin-top: 100px;
  }

  #about-TOC-face-pic {
    top: -50px;
    margin-top: 50px;
  }
}

/* Media Query for 480px */
@media screen and (max-width: 480px) {
  

  #afford-TOC-face {
    margin-bottom: -200px;
  }

  #life-TOC-face {
    margin-bottom: -200px;
  }

  #prog-TOC-face {
    margin-bottom: -200px;
  }

  #tool-TOC-face {
    margin-bottom: -200px;
  }

  #about-TOC-face {
    margin-bottom: -200px;
  }



}













@media (max-width: 1024px) {
  #sub-scholarship-chapter {
    font-size: 28px;
  }

  #sub-scholarship-ch-header-text {
    font-size: 56px;
    margin-bottom: -30px;
    margin-top: -15px;
  }

  #sub-scholarship-ch-text-two {
    font-size: 36px;
  }
}

@media (max-width: 768px) {
  #sub-scholarship-chapter {
    font-size: 22px;
  }

  #sub-scholarship-ch-header-text {
    font-size: 42px;
    margin-bottom: -10px; /* Further reduced margin */
    margin-top: -5px;     /* Further reduced margin */
  }

  #sub-scholarship-ch-text-two {
    font-size: 28px;
  }
}

@media (max-width: 480px) {
  #sub-scholarship-chapter {
    font-size: 14px;
    margin-top: 26px;
  }

  #sub-scholarship-ch-header-text {
    font-size: 18px;
    margin-bottom: -14px; /* Further reduced margin */
    margin-top: -5px;    /* Further reduced margin */
  }

  #sub-scholarship-ch-text-two {
    font-size: 18px;
  }
}






#scholar-scholar-div {
  margin-bottom: -200px;

  
  margin: auto;
  background-color: #f3f6fd;
}

.scholar-below-header-container {
  width: 80%;
  margin: 0 auto;
  background-color: #f3f6fd;

  
}














/* For screens 1024px and below */
@media (max-width: 1024px) {
  #sections-container {
    margin-bottom: -3%;
  }
}

/* For screens 768px and below */
@media (max-width: 768px) {
  #sections-container {
    margin-bottom: -5%;
  }
}

/* For screens 480px and below */
@media (max-width: 480px) {
  #sections-container {
    margin-bottom: -10%;
  }
}