@import url("https://fonts.googleapis.com/css?family=Open Sans");

@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css?family=Raleway");



/* Wrapper for the whole page */
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f3f6fd;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

/* Container for the login form and the image background */
.login-container {
  display: flex;
  width: 95%;
  max-width: 1300px;
  height: 650px; /* Fixed height to prevent the container from expanding */
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #e1e3e6;
}

/* Left side containing the login form */
.login-left {
  flex: 1;
  padding: 3rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Right side now fully occupied by the image */
.login-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100%; /* Ensure the right side matches the container height */
}

/* Full image inside the right side */
.login-image-full {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container without distortion */
  border-radius: 0px; /* Remove border-radius if you want a flush fit */

  animation: fadeIn .75s ease-in-out forwards; /* Applying the animation */
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Main container for the auth content */
.auth-main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Container for the form and its elements */
.auth-container {
  width: 100%;
  max-width: 380px;
}

/* Header for the auth section */
.auth-header {
  text-align: left;
}

/* Title styling in the header */
.auth-title h3 {
  color: #1f2937;
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 2.25rem;
  margin-bottom: 1rem;

  text-align: center;
}

/* Form styling */
.auth-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

/* Individual form group */
.form-group {
  width: 100%;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

/* Label styling */
.form-group label {
  display: block;
  font-size: 0.875rem;
  color: #4b5563;
  font-weight: 700;
  margin-bottom: 0.5rem;
  text-align: left;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

/* Input styling */
.form-group input {
  width: 100%;
  padding: 0.5rem 0.75rem;
  color: #6b7280;
  background-color: #f9fafb;
  outline: none;
  border: 1px solid #e5e7eb;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border-radius: 0.5rem;
  transition: all 0.3s;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

/* Focus state for inputs */
.form-group input:focus {
  border-color: #6366f1;
}

/* Error message styling */
.error-message {
  color: #dc2626;
  font-weight: 700;
  margin-top: 1rem;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

/* Submit button styling */
.submit-button {
  width: 100%;
  padding: 0.75rem 1rem;
  color: #ffffff;
  font-weight: 600; /* Adjusted to match sign-up button */
  border-radius: 0.5rem;
  transition: all 0.3s;
  background-color: #4f46e5; /* Reverted background color */
  cursor: pointer;
  border: 1px solid #e5e7eb; /* Adjusted border color */

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

/* Hover state for the submit button */
.submit-button:hover {
  background-color: #3630a0; /* Slightly darker on hover */
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

/* Disabled button state */
.disabled-button {
  background-color: #d1d5db;
  cursor: not-allowed;
}

/* Footer styling for links */
.auth-footer {
  text-align: center;
  font-size: 0.875rem;
  margin-top: 1rem;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

/* Separator styling between sections */
.separator {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;

  font-family: 'Poppins', sans-serif;
 
  font-style: normal;
}

/* Line in the separator */
.separator-line {
  flex: 1;
  height: 2px;
  background-color: #e5e7eb;
}

/* Text in the separator */
.separator-text {
  font-size: 0.875rem;
  font-weight: 700;
  margin: 0 0.5rem;
  text-align: center;
}

/* Google sign-in button */
.google-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 0.75rem; /* Adjusted to match sign-up button */
  color: #1f2937;
  border: 1px solid #e5e7eb; /* Adjusted border color */
  background-color: #ffffff;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.3s;
  cursor: pointer;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

/* Hover state for the Google button */
.google-button:hover {
  background-color: #f3f4f6;
  border-color: #e5e7eb; /* Matching hover border color */
}

/* Google icon styling */
.google-icon {
  width: 1.25rem;
  height: 1.25rem;
}
































/* Wrapper for the whole page */
.auth-page {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f6fd; /* Light background for contrast */
}

.auth-container-original {
  width: 35rem; /* Increased width for landscape mode */
  color: #4b5563;
  padding: 2rem; /* Increased padding for spacious layout */
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 0.75rem;

  /* border: 1px solid #e5e7eb;*/

  border: 1px solid #e1e3e6;
  background-color: #ffffff;
}

.auth-header-original {
  text-align: center; /* Left-align the header */
  margin-bottom: 1.5rem; /* Space between header and form */

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.auth-title-original h3 {
  color: #1f2937;
  font-size: 1.75rem; /* Larger font size for title */
  font-weight: 600;
  line-height: 2.25rem;

  font-family: 'Poppins', sans-serif;
 
  font-style: normal;
}

.auth-form-original {
  display: flex;
  flex-direction: column;
  gap: 1.5rem; /* More spacing between form elements */
  width: 100%;
}

.form-group-original {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns the input boxes to the left */

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.form-group-original label {
  display: block;
  font-size: 0.875rem;
  color: #1f2937; /* Darker color for better contrast */
  font-weight: 600; /* Slightly bolder */
  margin-bottom: 0.5rem;
  text-align: left; /* Align labels to the left */

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.form-group-original input {
  width: 90%; /* Shortened width for input boxes */
  padding: 0.75rem 1rem; /* Increased padding for larger input boxes */
  color: #4b5563;
  background-color: #f9fafb; /* Light background for input fields */
  outline: none;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  transition: all 0.3s;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.form-group-original input:focus {
  border-color: #6366f1;
}

.error-message-original {
  color: #dc2626;
  font-weight: 700;
  margin-top: -0.5rem; /* Adjust margin for better alignment */

  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-style: normal;
}

.submit-button-original {
  width: 100%;
  padding: 0.75rem 1rem;
  color: #ffffff;
  font-weight: 600; /* Make button text bolder */
  border-radius: 0.5rem;
  transition: all 0.3s;
  background-color: #4f46e5; /* Reverted background color */
  cursor: pointer;
  border: 1px solid #e5e7eb; /* Adjusted border color */

  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-style: normal;
}

.submit-button-original:hover {
  background-color: #3630a0; /* Slightly darker on hover */
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.disabled-button-original {
  background-color: #d1d5db;
  cursor: not-allowed;
}

.auth-footer-original {
  text-align: center;
  font-size: 0.875rem;
  margin-top: 1.5rem;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.auth-footer-agree-original {
  color: #6b7280;
  font-size: 0.875rem;
  margin-top: -5px;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.auth-link-original {
  text-decoration: underline;
  font-weight: 700;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.separator-original {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.separator-line-original {
  flex: 1;
  height: 2px;
  background-color: #e5e7eb;
}

.separator-text-original {
  font-size: 0.875rem;
  font-weight: 700;
  margin: 0 0.5rem;
}

.google-button-original {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 0.75rem;
  color: #1f2937; /* Darker color for contrast */
  border: 1px solid #e5e7eb;
  background-color: #ffffff;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.3s;
  cursor: pointer; /* Changes cursor to hand on hover */

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.google-button-original:hover {
  background-color: #f3f4f6;
}

.google-icon-original {
  width: 1.25rem;
  height: 1.25rem;
}

.verification-message-original {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #4CAF50;
  background-color: #dff0d8;
  color: #3c763d;
  border-radius: 5px;
  text-align: center;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}























/* Wrapper for the whole page */
.auth-page-reset {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f6fd; /* Light background for contrast */

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.auth-container-reset {
  width: 35rem; /* Increased width for landscape mode */
  color: #4b5563;
  padding: 2rem; /* Increased padding for spacious layout */
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 0.75rem;
  border: 1px solid #e5e7eb;
  background-color: #ffffff;
}

.auth-header-reset {
  text-align: center; /* Center-align the header */
  margin-bottom: 1.5rem; /* Space between header and form */

  font-family: 'Poppins', sans-serif;
 
  font-style: normal;
}

.auth-title-reset h3 {
  color: #1f2937;
  font-size: 1.75rem; /* Larger font size for title */
  font-weight: 600;
  line-height: 2.25rem;

  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-style: normal;
}

.auth-form-reset {
  display: flex;
  flex-direction: column;
  gap: 1.5rem; /* More spacing between form elements */
  width: 100%;
  align-items: center; /* Center-align form elements */
}

.form-group-reset {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Aligns the input boxes and labels to the center */

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.form-group-reset label {
  display: block;
  font-size: 0.875rem;
  color: #1f2937; /* Darker color for better contrast */
  font-weight: 600; /* Slightly bolder */
  margin-bottom: 0.5rem;
  text-align: center; /* Center-align the labels */

  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-style: normal;
}

.form-group-reset input {
  width: 90%; /* Shortened width for input boxes */
  padding: 0.75rem 1rem; /* Increased padding for larger input boxes */
  color: #4b5563;
  background-color: #f9fafb; /* Light background for input fields */
  outline: none;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  transition: all 0.3s;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.form-group-reset input:focus {
  border-color: #6366f1;
}

.error-message-reset {
  color: #dc2626;
  font-weight: 700;
  margin-top: -0.5rem; /* Adjust margin for better alignment */
}

.success-message-reset {
  color: #4caf50;
  font-weight: 700;
  margin-top: -0.5rem; /* Adjust margin for better alignment */

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.submit-button-reset {
  width: 100%;
  padding: 0.75rem 1rem;
  color: #ffffff;
  font-weight: 600; /* Make button text bolder */
  border-radius: 0.5rem;
  transition: all 0.3s;
  background-color: #4f46e5; /* Reverted background color */
  cursor: pointer;
  border: 1px solid #e5e7eb; /* Adjusted border color */

  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-style: normal;
}

.submit-button-reset:hover {
  background-color: #3630a0; /* Slightly darker on hover */
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.disabled-button-reset {
  background-color: #d1d5db;
  cursor: not-allowed;
}

.auth-footer-reset {
  text-align: center;
  font-size: 0.875rem;
  margin-top: 1.5rem;

  font-family: 'Poppins', sans-serif;
 
  font-style: normal;
}

.auth-footer-agree-reset {
  color: #6b7280;
  font-size: 0.875rem;
  margin-top: -5px;

  font-family: 'Poppins', sans-serif;
 
  font-style: normal;
}

.auth-link-reset {
  text-decoration: underline;
  font-weight: 700;
}

.separator-reset {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.separator-line-reset {
  flex: 1;
  height: 2px;
  background-color: #e5e7eb;
}

.separator-text-reset {
  font-size: 0.875rem;
  font-weight: 700;
  margin: 0 0.5rem;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}
























/* Wrapper for the whole page */
.auth-page-change-pass {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f6fd; /* Light background for contrast */

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.auth-container-change-pass {
  width: 35rem; /* Increased width for landscape mode */
  color: #4b5563;
  padding: 2rem; /* Increased padding for spacious layout */
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 0.75rem;
  border: 1px solid #e5e7eb;
  background-color: #ffffff;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.auth-header-change-pass {
  text-align: center; /* Center-align the header */
  margin-bottom: 1.5rem; /* Space between header and form */
}

.auth-title-change-pass h3 {
  color: #1f2937;
  font-size: 1.75rem; /* Larger font size for title */
  font-weight: 600;
  line-height: 2.25rem;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.auth-form-change-pass {
  display: flex;
  flex-direction: column;
  gap: 1.5rem; /* More spacing between form elements */
  width: 100%;
  align-items: center; /* Center-align form elements */
}

.form-group-change-pass {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Aligns the input boxes and labels to the center */

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.form-group-change-pass label {
  display: block;
  font-size: 0.875rem;
  color: #1f2937; /* Darker color for better contrast */
  font-weight: 600; /* Slightly bolder */
  margin-bottom: 0.5rem;
  text-align: center; /* Center-align the labels */

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.form-group-change-pass input {
  width: 90%; /* Shortened width for input boxes */
  padding: 0.75rem 1rem; /* Increased padding for larger input boxes */
  color: #4b5563;
  background-color: #f9fafb; /* Light background for input fields */
  outline: none;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  transition: all 0.3s;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.form-group-change-pass input:focus {
  border-color: #6366f1;
}

.error-message-change-pass {
  color: #dc2626;
  font-weight: 700;
  margin-top: -0.5rem; /* Adjust margin for better alignment */

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.success-message-change-pass {
  color: #4caf50;
  font-weight: 700;
  margin-top: -0.5rem; /* Adjust margin for better alignment */

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.submit-button-change-pass {
  width: 100%;
  padding: 0.75rem 1rem;
  color: #ffffff;
  font-weight: 600; /* Make button text bolder */
  border-radius: 0.5rem;
  transition: all 0.3s;
  background-color: #4f46e5; /* Reverted background color */
  cursor: pointer;
  border: 1px solid #e5e7eb; /* Adjusted border color */

  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-style: normal;
}

.submit-button-change-pass:hover {
  background-color: #3630a0; /* Slightly darker on hover */
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.disabled-button-change-pass {
  background-color: #d1d5db;
  cursor: not-allowed;
}

.auth-footer-change-pass {
  text-align: center;
  font-size: 0.875rem;
  margin-top: 1.5rem;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}






















/* Media Queries for Screen Sizes of 1024px, 768px, and 480px */

/* 1024px Screen Size */
@media (max-width: 1024px) {
  .login-container {
    flex-direction: row;
    height: auto;
  }

  .login-left, .login-right {
    flex: 1;
    width: 50%;
  }

  .auth-title h3, .auth-title-original h3, .auth-title-reset h3, .auth-title-change-pass h3 {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .submit-button, .submit-button-original, .submit-button-reset, .submit-button-change-pass {
    padding: 0.65rem 0.85rem;
  }

  .auth-container, .auth-container-original, .auth-container-reset, .auth-container-change-pass {
    max-width: 36rem; /* Increase the max-width */
    padding: 2rem; /* Increase padding for better spacing */
    margin: 0 auto; /* Center the container */
  }
}

/* 768px Screen Size */
@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
    height: auto;
  }

  .login-right {
    display: none; /* Remove the right side */
  }

  .login-left {
    width: 100%;
    padding: 2rem 1rem;
    padding-left: 0%;
  }

  .auth-title h3, .auth-title-original h3, .auth-title-reset h3, .auth-title-change-pass h3 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .submit-button, .submit-button-original, .submit-button-reset, .submit-button-change-pass {
    padding: 0.6rem 0.8rem;
    font-size: 0.875rem;
  }

  .auth-container, .auth-container-original, .auth-container-reset, .auth-container-change-pass {
    max-width: 30rem; /* Increase the max-width */
    padding: 1.75rem; /* Increase padding for better spacing */
    margin: 0 auto; /* Center the container */
  }

  .form-group input, .form-group-original input, .form-group-reset input, .form-group-change-pass input {
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
  }

  .form-group label, .form-group-original label, .form-group-reset label, .form-group-change-pass label {
    font-size: 0.75rem;
  }

  .google-button, .google-button-original {
    padding: 0.65rem;
    font-size: 0.875rem;
  }
}

/* 480px Screen Size */
@media (max-width: 480px) {
  .login-container {
    flex-direction: column;
    height: auto;
  }

  .login-right {
    display: none; /* Remove the right side */
  }

  .login-left {
    width: 100%;
    padding: 1.5rem 1rem;
    padding-left: 0%;
  }

  .auth-title h3, .auth-title-original h3, .auth-title-reset h3, .auth-title-change-pass h3 {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .submit-button, .submit-button-original, .submit-button-reset, .submit-button-change-pass {
    padding: 0.5rem 0.75rem;
    font-size: 0.75rem;
  }

  .auth-container, .auth-container-original, .auth-container-reset, .auth-container-change-pass {
    max-width: 24rem; /* Increase the max-width */
    padding: 1.5rem; /* Increase padding for better spacing */
    margin: 0 auto; /* Center the container */
  }

  .form-group input, .form-group-original input, .form-group-reset input, .form-group-change-pass input {
    padding: 0.4rem 0.6rem;
    font-size: 0.75rem;
  }

  .form-group label, .form-group-original label, .form-group-reset label, .form-group-change-pass label {
    font-size: 0.625rem;
  }

  .google-button, .google-button-original {
    padding: 0.5rem;
    font-size: 0.75rem;
  }

  .auth-footer, .auth-footer-original, .auth-footer-reset, .auth-footer-change-pass {
    font-size: 0.75rem;
  }

  .separator-text, .separator-text-original, .separator-text-reset {
    font-size: 0.75rem;
  }
}

































/* Media Queries for Screen Sizes of 1024px, 768px, and 480px */

/* 1024px Screen Size */
@media (max-width: 1024px) {
  .auth-page, .auth-page-reset, .auth-page-change-pass {
    width: 100%;
  }

  .auth-container-original {
    max-width: 36rem; /* Increase the max-width */
    padding: 2rem; /* Increase padding for better spacing */
    margin: 0 auto; /* Center the container */
  }

  .auth-container-reset, .auth-container-change-pass {
    max-width: 28rem; /* Keep the original max-width */
    padding: 1.5rem;
    margin: 0 auto; /* Center the container */
  }

  .auth-title-original h3, .auth-title-reset h3, .auth-title-change-pass h3 {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .form-group-original input, .form-group-reset input, .form-group-change-pass input {
    padding: 0.7rem 0.9rem;
    width: 100%; /* Ensure inputs take full width */
    box-sizing: border-box; /* Prevent overflow */
  }

  .auth-footer-original, .auth-footer-reset, .auth-footer-change-pass {
    font-size: 0.875rem;
  }

  .google-button-original {
    padding: 0.7rem;
  }
}

/* 768px Screen Size */
@media (max-width: 768px) {
  .auth-page, .auth-page-reset, .auth-page-change-pass {
    width: 100%;
  }

  .auth-container-original {
    max-width: 30rem; /* Increase the max-width */
    padding: 1.75rem; /* Increase padding for better spacing */
    margin: 0 auto; /* Center the container */
  }

  .auth-container-reset, .auth-container-change-pass {
    max-width: 23rem; /* Keep the original max-width */
    padding: 1.25rem;
    margin: 0 auto; /* Center the container */
  }

  .auth-title-original h3, .auth-title-reset h3, .auth-title-change-pass h3 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .form-group-original input, .form-group-reset input, .form-group-change-pass input {
    width: 100%; /* Ensure inputs take full width */
    padding: 0.65rem 0.85rem;
    font-size: 0.875rem;
    box-sizing: border-box; /* Prevent overflow */
  }

  .form-group-original label, .form-group-reset label, .form-group-change-pass label {
    font-size: 0.75rem;
  }

  .google-button-original {
    padding: 0.6rem;
    font-size: 0.875rem;
  }

  .auth-footer-original, .auth-footer-reset, .auth-footer-change-pass {
    font-size: 0.75rem;
  }

  .separator-text-original, .separator-text-reset {
    font-size: 0.75rem;
  }
}

/* 480px Screen Size */
@media (max-width: 480px) {
  .auth-page, .auth-page-reset, .auth-page-change-pass {
    width: 100%;
  }

  .auth-container-original {
    max-width: 24rem; /* Increase the max-width */
    padding: 1.5rem; /* Increase padding for better spacing */
    margin: 0 auto; /* Center the container */
  }

  .auth-container-reset, .auth-container-change-pass {
    max-width: 18rem; /* Keep the original max-width */
    padding: 1rem;
    margin: 0 auto; /* Center the container */
  }

  .auth-title-original h3, .auth-title-reset h3, .auth-title-change-pass h3 {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .form-group-original input, .form-group-reset input, .form-group-change-pass input {
    width: 100%; /* Ensure inputs take full width */
    padding: 0.5rem 0.75rem;
    font-size: 0.75rem;
    box-sizing: border-box; /* Prevent overflow */
  }

  .form-group-original label, .form-group-reset label, .form-group-change-pass label {
    font-size: 0.625rem;
  }

  .google-button-original {
    padding: 0.5rem;
    font-size: 0.75rem;
  }

  .auth-footer-original, .auth-footer-reset, .auth-footer-change-pass {
    font-size: 0.75rem;
  }

  .separator-text-original, .separator-text-reset {
    font-size: 0.75rem;
  }
}
