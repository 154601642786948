/* General Container */
.career-search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  margin-top: -50px;
  background-color: #f3f6fd;

  padding-top: 100px;
}

/* Title */
.career-search-title {
  font-size: 32px;
  font-weight: 700;
  color: #2c3e50;
  margin-bottom: 40px;
  text-align: center;
}

/* Content Layout */
.career-search-content {
  display: flex;
  width: 100%;
  max-width: 1400px;
  margin-top: 20px;
  align-items: flex-start; /* Keeps the sidebar and card grid aligned */
}

/* Sidebar */
.career-search-sidebar {
  width: 20%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  /* Keeps the sidebar height dynamic based on content */
  max-height: 100%;
  position: sticky;
  top: 80px; /* Adjusted to ensure the sidebar is below the navbar */
}

.career-search-sidebar-section {
  margin-bottom: 30px;
}

.career-search-input {
  width: 80%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ced6e0;
  border-radius: 6px;
  background-color: #f9f9f9;
  color: #273562;

  font-family: "Poppins", sans-serif;

  font-style: normal;
}

.career-card-type {
  font-family: "Raleway", sans-serif;

  font-style: normal;
}

.career-filter-label {
  font-size: 14px;
  font-weight: 600;
  color: #273562;
  display: block;
  margin-bottom: 8px;

  font-family: "Raleway", sans-serif;

  font-style: normal;
}

/* Add a new class to apply a different color when a year is selected */
.selected-option {
  background-color: #8bb4d6; /* Light blue background when selected */
  color: #273562; /* Change text color when selected */
}

.career-filter-select option {
  color: #000000; /* Default color for unselected options */
}

.career-filter-select {
  width: 90%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ced6e0;
  border-radius: 6px;
  background-color: #f9f9f9;
  color: #273562;

  font-family: "Poppins", sans-serif;

  font-style: normal;
}

/* Card Grid */
.career-card-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: 80%;
  padding-left: 20px;
}

/* Individual Cards */
.career-card {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.career-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.career-card-image-container {
  width: 100%;
  height: 160px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.career-card-image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.career-card-content {
  padding: 20px;
  flex-grow: 1; /* Ensures content takes up the remaining space */

  border-top: 1.5px solid rgb(199, 198, 198); /* 1px for a thin border, solid for the style, and grey for the color */
}

.career-card-title {
  font-size: 18px;
  font-weight: 700;
  color: #273562;
  margin-bottom: 10px;

  font-family: "Raleway", sans-serif;

  font-style: normal;
}

.career-card-description {
  font-size: 14px;
  color: #7f8c8d;
  margin-bottom: 15px;

  font-family: "Poppins", sans-serif;

  font-style: normal;
}

.career-card-link {
  text-decoration: none;
  color: #2980b9;
  font-weight: 600;
  transition: color 0.2s ease;
  margin-top: auto; /* Pushes the link to the bottom of the card */

  font-family: "Raleway", sans-serif;

  font-style: normal;
}

.career-card-link:hover {
  color: #1a5276;
}

/* Footer */
.career-search-footer {
  margin-top: 40px;
  text-align: center;
}

.load-more-button {
  padding: 12px 30px;
  font-size: 16px;
  color: #ffffff;
  background-color: #4f46e5;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.load-more-button:hover {
  background-color: #3630a0;
}

/* 1024px Screen Size */
@media (max-width: 1024px) {
  .career-search-container {
    padding: 30px 15px;
    padding-top: 80px;
  }

  .career-search-title {
    font-size: 28px;
    margin-bottom: 30px;
  }

  .career-search-sidebar {
    width: 25%;
    padding: 15px;
  }

  .career-card-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    width: 70%; /* Reduced width for career cards */
  }

  .career-card-content {
    padding: 15px;
  }
}

/* 768px Screen Size */
@media (max-width: 768px) {
  .career-search-container {
    padding: 25px 10px;
    padding-top: 70px;
  }

  .career-search-title {
    font-size: 24px;
    margin-bottom: 25px;
  }

  .career-search-content {
    flex-direction: column;
    align-items: center;
  }

  .career-search-sidebar {
    width: 100%;
    max-height: none;
    position: relative;
    top: 0;
    margin-bottom: 20px;
    background-color: transparent;
    box-shadow: none;
  }

  .career-card-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    width: 95%; /* Reduced width for career cards */
    padding-left: 0;
  }

  .career-card-content {
    padding: 10px;
  }

  .career-card-title {
    font-size: 16px;
  }

  .career-card-description {
    font-size: 12px;
  }
}

/* 480px Screen Size */
@media (max-width: 480px) {
  .career-search-container {
    padding: 20px 10px;
    padding-top: 60px;
  }

  .career-search-title {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .career-search-sidebar {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    box-shadow: none;
    background-color: transparent;
  }

  .career-search-input {
    width: 75%; /* Ensuring it doesn't break out of the screen */
    padding: 8px;
    font-size: 14px;
  }

  .career-filter-select {
    width: 75%; /* Less wide for 480px screen size */
    padding: 8px;
    font-size: 14px;
  }

  .career-card-grid {
    grid-template-columns: 1fr;
    gap: 10px;
    width: 75%; /* Further reduced width for career cards */
  }

  .career-card-content {
    padding: 8px;
  }

  .career-card-title {
    font-size: 14px;
  }

  .career-card-description {
    font-size: 12px;
  }

  .load-more-button {
    padding: 10px 25px;
    font-size: 14px;
  }

  .career-card {
    margin-bottom: 25px; /* Added more space at the bottom of the career cards */
  }
}
