@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

body {
  font-family: 'Poppins', sans-serif;
  
  font-style: normal;

  background-color: #f0f2f5;
  margin: 0;
  padding: 0;
}

.profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f3f6fd;
  padding: 2rem;
  margin-top: 60px;
}

.profile {
  width: 100%;
  max-width: 65%;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile h2 {
  color: #1a1a1a;
  text-align: center;
  margin: 0;
  padding: 2rem;
  background-color: #273562 ;
  font-size: 2rem;
  font-weight: 700;
  width: 100%;


  color: white;
}

.profile-content {
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.profile-section h3 {
  color: #333;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: 500;

  background: rgba(170, 181, 206, 0.753);
  padding: 20px;
  border-radius: 18px;
}

.user-name {
  color: #273562 ;
  font-weight: 700;
}

.profile-picture {
  text-align: center;
  margin-bottom: 2rem;
  position: relative;
}

.profile-picture img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #273562 ;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-picture input[type="file"] {
  display: none;
}

.upload-button {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  margin-left: -15px;
  width: 160px;
  background-color: #4f46e5 ;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
}

.upload-button:hover {
  background-color: #3630a0;
}

.profile-form {
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 5%;
}

.profile-form .form-group {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.profile-form label {
  display: block;
  margin-bottom: 0.5rem;
  color: #333;
  font-weight: 500;
  width: 100%;
  font-size: 1rem;
}

.profile-form input[type="text"],
.profile-form input[type="email"],
.profile-form input[type="url"],
.profile-form select,
.profile-form textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
  font-family: "Roboto", sans-serif;
}

.profile-form textarea {
  height: 100px;
  resize: none;
}

.profile-form input[type="text"]:focus,
.profile-form input[type="email"]:focus,
.profile-form input[type="url"]:focus,
.profile-form select:focus,
.profile-form textarea:focus {
  outline: none;
  border-color: #273562 ;
}

.error-message {
  color: #ff3b30;
  margin-bottom: 1rem;
  font-weight: 500;
  font-size: 1rem;
}

.button-group {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;
  gap: 1rem;
}

.button-group button {
  padding: 0.75rem 2rem; /* Increased padding to make the buttons wider */
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
  width: 100%;
  max-width: 250px; /* Increased max-width for the buttons */
  font-family: "Roboto", sans-serif;
}

.button-group button:first-child {
  background-color: #4f46e5; /* Light green */
  color: #ffffff;
}

.button-group button:last-child {
  background-color: #f0f0f0;
  color: #333;
}

.button-group button:hover {
  opacity: 0.9;
  transform: translateY(-1px);
}

.button-group button:active {
  transform: translateY(0);
}

.profile-message {
  text-align: center;
  font-size: 1.2rem;
  color: #666;
  margin-top: 2rem;
  font-family: "Roboto", sans-serif;
}

/* Styling for Remove, Add Test Score, and Add Activity Buttons */
.remove-button {
  background-color: #e75d5d;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
  width: 100%;
  max-width: 200px;
  margin-top: 1rem;
  margin-right: 20px;

  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-style: normal;
}

.add-test-score-button,
.add-activity-button {
  background-color: #1a202c;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
  width: 100%;
  max-width: 200px;
  margin-top: 1rem;

  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-style: normal;
}

.remove-button:hover {
  background-color: #e63939;
  transform: translateY(-1px);
}

.add-test-score-button:hover,
.add-activity-button:hover {
  background-color: #2d3748;
  transform: translateY(-1px);
}

.remove-button:active,
.add-test-score-button:active,
.add-activity-button:active {
  transform: translateY(0);
}

/* Align the new button groups horizontally */
.button-group-horizontal {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  width: 100%;
}



.box-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  justify-content: center;
}

.box-option {
  flex: 1 1 calc(50% - 10px);
  padding: 20px;
  border: 2px solid #ccc;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.box-option.selected {
  background-color: #1a202c;
  color: white;
  border-color: #273562 ;
}

.box-option:hover {
  border-color: #273562 ;


  
}







#two-buttons-profile {
  background: red;
}

.form-group-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}






















/* General Styles */
.career-goal {
  margin-top: 40px; /* Added more top margin for career goal text */
}

/* For screen sizes 1024px */
@media screen and (max-width: 1024px) {
  .profile-container {
    padding: 1.5rem;
    margin-top: 80px; /* Increased top margin */
  }

  .profile {
    max-width: 75%;
  }

  .profile h2 {
    font-size: 1.8rem;
    padding: 1.5rem;
  }

  .profile-section h3 {
    font-size: 1.3rem;
  }

  .profile-picture img {
    width: 130px;
    height: 130px;
  }

  .profile-form {
    width: 90%;
    margin-top: 4%;
  }

  .profile-form label {
    font-size: 0.9rem;
  }

  .profile-form input[type="text"],
  .profile-form input[type="email"],
  .profile-form input[type="url"],
  .profile-form select,
  .profile-form textarea {
    font-size: 0.95rem;
  }

  .profile-form input[type="date"] {
    width: 100%; /* Ensures date of birth input does not break out */
    box-sizing: border-box;
  }

  .button-group button {
    padding: 0.7rem 1.5rem;
    max-width: 220px;
    font-size: 0.95rem;
  }

  .remove-button,
  .add-test-score-button,
  .add-activity-button {
    max-width: 180px;
    font-size: 0.95rem;
  }

  .upload-button {
    margin-top: -18px;
    margin-left: -18px;
    width: 150px;
    padding: 0.3rem 0.6rem;  /* Further reduce padding */
    font-size: 0.9rem;       /* Further reduce font size */
  }
}

/* For screen sizes 768px */
@media screen and (max-width: 768px) {
  .profile-container {
    padding: 1.5rem;
    margin-top: 70px; /* Increased top margin */
  }

  .profile {
    max-width: 85%;
  }

  .profile h2 {
    font-size: 1.6rem;
    padding: 1.2rem;
  }

  .profile-section h3 {
    font-size: 1.2rem;
  }

  .profile-picture img {
    width: 110px;
    height: 110px;
  }

  .profile-form {
    width: 90%;
    margin-top: 3%;
  }

  .profile-form label {
    font-size: 0.85rem;
  }

  .profile-form input[type="text"],
  .profile-form input[type="email"],
  .profile-form input[type="url"],
  .profile-form select,
  .profile-form textarea {
    font-size: 0.9rem;
  }

  .profile-form input[type="date"] {
    width: 100%; /* Ensures date of birth input does not break out */
    box-sizing: border-box;
  }

  .button-group button {
    padding: 0.6rem 1.3rem;
    max-width: 200px;
    font-size: 0.9rem;
  }

  .remove-button,
  .add-test-score-button,
  .add-activity-button {
    max-width: 160px;
    font-size: 0.9rem;
  }

  .upload-button {
    margin-top: -15px;
    margin-left: -28px;
    width: 150px;
    padding: 0.3rem 0.6rem;  /* Further reduce padding */
    font-size: 0.9rem;       /* Further reduce font size */
  }
}

/* For screen sizes 480px */
@media screen and (max-width: 480px) {
  body {
    font-size: 0.85rem;
  }

  .profile-container {
    padding: 1rem;
    margin-top: 60px; /* Increased top margin */
  }

  .profile {
    max-width: 95%;
  }

  .profile h2 {
    font-size: 1.4rem;
    padding: 1rem;
  }

  .profile-section h3 {
    font-size: 1rem;
    padding: 15px;
  }

  .profile-picture img {
    width: 90px;
    height: 90px;
  }

  .profile-form {
    width: 90%;
    margin-top: 2%;
  }

  .profile-form label {
    font-size: 0.75rem;
  }

  .profile-form input[type="text"],
  .profile-form input[type="email"],
  .profile-form input[type="url"],
  .profile-form select,
  .profile-form textarea {
    font-size: 0.8rem;
    padding: 0.6rem; /* Adjusted padding to fit within smaller screens */
    width: 100%; /* Ensured full width within the form */
    box-sizing: border-box; /* Ensures padding and border are included in the width */
  }

  .profile-form input[type="date"] {
    width: 100%; /* Ensures date of birth input does not break out */
    box-sizing: border-box;
  }

  .button-group button {
    padding: 0.5rem 1rem;
    max-width: 180px;
    font-size: 0.85rem;
  }

  .remove-button,
  .add-test-score-button,
  .add-activity-button {
    max-width: 140px;
    font-size: 0.85rem;
    padding: 0.6rem 1rem; /* Adjusted height */
  }

  .upload-button {
    margin-top: -4px;
    margin-left: -14px;
    width: 100px;
    padding: 0.3rem 0.6rem;  /* Further reduce padding */
    font-size: 0.6rem;       /* Further reduce font size */
  }
}
