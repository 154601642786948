.comment-respond {
  max-width: 600px;
  background-color: #f7f9fc;
  border-radius: 12px;
  padding: 20px;
  margin: 30px auto;
  border: 1px solid #e6e8eb;
  

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  
}

.comment-reply-title {
  font-size: 24px;
  margin-bottom: 1.5rem;
  color: #1a202c;
  font-weight: 700;
  text-align: left;

  font-family: 'Poppins', sans-serif;
 
  font-style: normal;
}

.comment-instructions,
.form-group label,
.comment-form input[type="text"],
.comment-form input[type="email"],
.comment-form textarea,
.error-message {
  font-size: 16px;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.required {
  color: red;
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.form-group {
  margin-bottom: 20px;
  width: 100%; /* Ensure all form-group elements take full width */
}

.comment-form input[type="text"],
.comment-form input[type="email"] {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccd0d5;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  background-color: #fff;
  
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.comment-form textarea {
  width: calc(96%);
  height: 150px;
  padding: 12px;
  border: 1px solid #ccd0d5;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  background-color: #fff;
  
  transition: all 0.3s ease;
  resize: vertical;
}

.comment-form input[type="text"]:focus,
.comment-form input[type="email"]:focus,
.comment-form textarea:focus {
  border-color: #3b82f6;
}

.comment-form label {
  display: block;
  margin-bottom: 5px;
  color: #1a202c;
  font-weight: 500;
  text-align: left; /* Ensure labels are aligned to the left */
}

.submit {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 5px;
  background-color: #1a202c;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease;

  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-style: normal;
}

.submit:hover {
  background-color: #2d3748;
}

.error-message {
  color: #e74c3c;
  font-size: 16px;
  margin-top: 10px;
  text-align: center;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}

.comment-instructions {
  font-size: 16px;
  color: #6c757d;
  margin-bottom: 20px; /* Reduced margin to align better with image */
  text-align: left;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
}



#comment-body {
  background: #f3f6fd;

  padding-top: 2%;
  padding-bottom: 1%;

  margin-top: -2%;

  font-family: 'Poppins', sans-serif;
  
  font-style: normal;
  
}













/* Media Query for screens with a max width of 1024px */
@media (max-width: 1024px) {
  .comment-respond {
    max-width: 80%;
    padding: 18px;
  }

  .comment-reply-title {
    font-size: 22px;
  }

  .form-row {
    flex-direction: column;
    gap: 15px;
  }

  .comment-form textarea {
    width: calc(95%);
  }

  .comment-instructions,
  .form-group label,
  .comment-form input[type="text"],
  .comment-form input[type="email"],
  .comment-form textarea,
  .error-message {
    font-size: 15px;
  }

  .submit {
    font-size: 15px;
  }

  #comment-body {
    padding-top: 5%; /* More top padding for 1024px screens */
  }
}

/* Media Query for screens with a max width of 768px */
@media (max-width: 768px) {
  .comment-respond {
    max-width: 500px;
    padding: 16px;
  }

  .comment-reply-title {
    font-size: 20px;
  }

  .form-row {
    flex-direction: column;
    gap: 12px;
  }

  .comment-form textarea {
    width: calc(94%);
  }

  .comment-instructions,
  .form-group label,
  .comment-form input[type="text"],
  .comment-form input[type="email"],
  .comment-form textarea,
  .error-message {
    font-size: 14px;
  }

  .submit {
    font-size: 14px;
  }

  #comment-body {
    padding-top: 6%; /* More top padding for 1024px screens */
  }
}

/* Media Query for screens with a max width of 480px */
@media (max-width: 480px) {
  .comment-respond {
    width: 82.5%;
    padding: 14px;
    margin: 20px auto;
  }

  .comment-reply-title {
    font-size: 18px;
  }

  .form-row {
    flex-direction: column;
    gap: 10px;
  }

  .comment-form textarea {
    width: calc(93%);
  }

  .comment-instructions,
  .form-group label,
  .comment-form input[type="text"],
  .comment-form input[type="email"],
  .comment-form textarea,
  .error-message {
    font-size: 13px;
  }

  .submit {
    font-size: 13px;
  }

  #comment-body {
    padding-top: 12%; /* More top padding for 1024px screens */
  }
}








